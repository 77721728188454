import React, { useRef, useState } from "react";
import "./QrManager.scss";

import { QRCode } from "react-qrcode-logo";
import StyledButton from "../StyledButton/StyledButton";
import Slider from "rc-slider";
import { HexColorPicker } from "react-colorful";
import SingleImageUploader from "../SingleImageUploader/SingleImageUploader";
import Label from "../Label/Label";
import Spacer from "../Spacer/Spacer";
import { useUser } from "../../hooks/useUser";
import useTranslateDocument from "../../hooks/useTranslateDocument";
import QrMultimaker from "../QrMultimaker/QrMultimaker";
import Modal from "../Modal/Modal";
const factor = 1;
const cornerStyles = {
  rund: [10 * factor, 10 * factor, 10 * factor, 10 * factor],
  eckig: [0, 0, 0, 0],
  tropfen: [
    [10 * factor, 10 * factor, 0, 10 * factor],
    [10 * factor, 10 * factor, 10 * factor, 0],
    [10 * factor, 0, 10 * factor, 10 * factor],
  ],
};

export default function QrManager() {
  const qrCodeRef = useRef();
  // function to download the QR code
  let { user } = useUser();
  let {
    data: { qrStyle },
    setField,
    updateDocument,
    updateStatus,
  } = useTranslateDocument("/clients/" + user.uid);
  let [showModal, setShowModal] = useState(false);
  if (!qrStyle) {
    return null;
  }

  return (
    <div className="QrManager">
      <div className="title">QR Codes Einrichten</div>
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false);
          }}
        >
          <QrMultimaker qrStyle={qrStyle}></QrMultimaker>
        </Modal>
      )}

      <Label>Hier können Sie das aussehen Ihres QR-Codes einstellen</Label>
      <Spacer h={24}></Spacer>
      <div className="flex">
        <div className="left" style={{ zoom: 1 / factor }}>
          <div className="box shadow">
            <QRCode
              ref={qrCodeRef}
              bgColor={"#ffffff00"}
              fgColor={qrStyle.color}
              value={"https://web.de"}
              enableCORS
              size={190 * factor}
              quietZone={20 * factor}
              qrStyle={qrStyle.type}
              ecLevel={"H"}
              removeQrCodeBehindLogo
              eyeRadius={cornerStyles[qrStyle.cornerStyle]}
              logoImage={qrStyle.image}
              logoHeight={qrStyle.size}
              logoWidth={qrStyle.size}
            ></QRCode>
          </div>
        </div>
        <div className="right">
          <div className="box shadow">
            <SingleImageUploader
              label={"QR-Code Logo"}
              value={qrStyle.image}
              onChange={(v) => {
                setField("qrStyle", { ...qrStyle, image: v });
              }}
            ></SingleImageUploader>
            {qrStyle.image && (
              <>
                <Label>Größe des Logos</Label>
                <Slider
                  value={qrStyle.size}
                  min={10 * factor}
                  max={40 * factor}
                  step={1 * factor}
                  onChange={(value) =>
                    setField("qrStyle", { ...qrStyle, size: value })
                  }
                />
              </>
            )}
          </div>
          <Spacer h={24}></Spacer>
          <div className="flex">
            <div style={{ flex: 1 }} className="box shadow">
              <Label>Stil des QR-Codes</Label>
              <div className="dotStyles">
                <div
                  className={
                    "dotStyle " + (qrStyle.type === "squares" ? "selected" : "")
                  }
                  onClick={() =>
                    setField("qrStyle", { ...qrStyle, type: "squares" })
                  }
                >
                  <img alt="qr square" src="/qr/qrsquares.png"></img>
                </div>
                <div
                  className={
                    "dotStyle " + (qrStyle.type === "dots" ? "selected" : "")
                  }
                  onClick={() =>
                    setField("qrStyle", { ...qrStyle, type: "dots" })
                  }
                >
                  <img alt="qr dots" src="/qr/qrdots.png"></img>
                </div>
              </div>
              <Label>Stil der Ecken</Label>
              <div className="cornerStyles">
                <div
                  className={
                    "cornerStyle " +
                    (qrStyle.cornerStyle === "eckig" ? "selected" : "")
                  }
                  onClick={() =>
                    setField("qrStyle", { ...qrStyle, cornerStyle: "eckig" })
                  }
                >
                  <img alt="corner style square" src="/qr/eckig.png"></img>
                </div>
                <div
                  className={
                    "cornerStyle " +
                    (qrStyle.cornerStyle === "rund" ? "selected" : "")
                  }
                  onClick={() =>
                    setField("qrStyle", { ...qrStyle, cornerStyle: "rund" })
                  }
                >
                  <img alt="corner style round" src="/qr/rund.png"></img>
                </div>
                <div
                  className={
                    "cornerStyle " +
                    (qrStyle.cornerStyle === "tropfen" ? "selected" : "")
                  }
                  onClick={() =>
                    setField("qrStyle", {
                      ...qrStyle,
                      cornerStyle: "tropfen",
                    })
                  }
                >
                  <img alt="corner style drop" src="/qr/tropfen.png"></img>
                </div>
              </div>
            </div>
            <div
              className="box shadow"
              style={{ display: "flex", flexDirection: "column", flex: 1 }}
            >
              <Label>Farbe wählen</Label>
              <HexColorPicker
                style={{ flex: 1, width: "100%" }}
                color={qrStyle.color}
                onChange={(color) =>
                  setField("qrStyle", { ...qrStyle, color: color })
                }
              />
            </div>
          </div>
          <Spacer h={24}></Spacer>
          <StyledButton
            color="grey"
            onClick={() => {
              setShowModal(true);
            }}
          >
            QR Codes herunterladen
          </StyledButton>
          <Spacer h={12}></Spacer>
          <StyledButton status={updateStatus} onClick={() => updateDocument()}>
            Speichern
          </StyledButton>
        </div>
      </div>
    </div>
  );
}
