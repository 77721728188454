import { Canvas } from "@react-three/fiber";
import React from "react";
import { CameraControls } from "../../utils/camera-controls";
import { Floor } from "../FullMap/FullMap";
import "./SlimMap.scss";

export default function SlimMap({ floorplan, children, onClick = () => {} }) {
  return (
    <div className="SlimMap">
      <Canvas orthographic camera={{ zoom: 40 }} style={{ height: 600 }}>
        <CameraControls></CameraControls>
        <Floor background={floorplan} onClick={onClick}></Floor>
        {children}
      </Canvas>
    </div>
  );
}
