import React from "react";
import { FaTrash } from "react-icons/fa";
import ImageUploader from "../ImageUploader/ImageUploader";
import Label from "../Label/Label";
import "./SingleImageUploader.scss";

export default function SingleImageUploader({
  value,
  onChange,
  label,
  ulLabel = "",
}) {
  return (
    <div className="SingleImageUploader">
      <Label>{label}</Label>
      {!value && (
        <div className="ulWrap">
          <ImageUploader
            label={ulLabel}
            accept={{ "image/png": [".png"], "image/jpg": [".jpeg", ".jpg"] }}
            value={value}
            onChange={(v) => onChange(v)}
          ></ImageUploader>
        </div>
      )}
      {value && (
        <div style={{ textAlign: "center" }}>
          <img
            alt={"Imag"}
            onClick={() => {}}
            src={value}
            style={{
              width: 300,
              height: 300,
              objectFit: "contain",
              marginRight: 4,
              margin: "0 auto",
            }}
          ></img>
          <div
            className="removeImageButton"
            onClick={() => {
              onChange(null);
            }}
          >
            <FaTrash></FaTrash>
            Bild löschen
          </div>
        </div>
      )}
    </div>
  );
}
