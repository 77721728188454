import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExhibitForm from "../../atoms/ExhibitForm/ExhibitForm";
import Layout from "../../atoms/Layout/Layout";

import useTranslateDocument from "../../hooks/useTranslateDocument";
import "./EditExhibit.scss";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import StyledButton from "../../atoms/StyledButton/StyledButton";
import Spacer from "../../atoms/Spacer/Spacer";
import ShortLinkMaker from "../../atoms/ShortLinkMaker/ShortLinkMaker";
import Modal from "../../atoms/Modal/Modal";
import { FaQrcode, FaTrash } from "react-icons/fa";

export default function EditExhibit() {
  let { id } = useParams();
  let { data, updateDocument, updateStatus, setField } = useTranslateDocument(
    "exhibits/" + id
  );
  const nav = useNavigate();
  const functions = getFunctions();
  const deleteExhibit = httpsCallable(functions, "deleteExhibit");
  const [deleteStatus, setDeleteStatus] = useState("start");
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <Layout className="EditExhibit" title="Exponat bearbeiten">
      {data && data.id && (
        <>
          {modalOpen && (
            <Modal
              onClose={() => {
                setModalOpen(false);
              }}
            >
              <ShortLinkMaker targetId={data.id}></ShortLinkMaker>
            </Modal>
          )}
          <ExhibitForm
            value={data}
            secondSave
            status={updateStatus}
            setField={setField}
            onComplete={(v) =>
              updateDocument().then(() => {
                //window.history.back();
              })
            }
          >
            <StyledButton
              color={"grey"}
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <FaQrcode></FaQrcode>
              <Spacer w={8}></Spacer>
              QR Code Einstellungen
            </StyledButton>
          </ExhibitForm>
          <Spacer h={8}></Spacer>
          <StyledButton
            color={"red"}
            status={deleteStatus}
            onClick={() =>
              confirmAlert({
                title: "Sind Sie sicher?",
                message: "Exponat unwiderruflich löschen",
                buttons: [
                  {
                    label: "Ja",
                    onClick: () => {
                      setDeleteStatus("loading");
                      deleteExhibit({ exhibitId: id }).then(() => {
                        setDeleteStatus("done");
                        nav(-1);
                      });
                    },
                  },
                  {
                    label: "Nein",
                    onClick: () => {},
                  },
                ],
              })
            }
          >
            <FaTrash></FaTrash>
            <Spacer w={8}></Spacer>
            Exponat löschen
          </StyledButton>
        </>
      )}
    </Layout>
  );
}
