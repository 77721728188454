import React from "react";
import Label from "../Label/Label";
import "./StyledTextarea.scss";

export default function StyledTextarea({ value, onChange, label }) {
  return (
    <div className="StyledTextarea">
      <Label>{label}</Label>
      <textarea
        type="text"
        className="styledTextarea"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}
