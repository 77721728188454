import { useTexture, Html } from "@react-three/drei";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { updateDocument } from "../../lib/fbHelpers";
import * as THREE from "three";
import "./FullMap.scss";
import create from "zustand";

import { useGesture } from "@use-gesture/react";
import { useSpring } from "@react-spring/web";
import { a } from "@react-spring/three";
import { useNavigate } from "react-router-dom";
import useTranslateDocument from "../../hooks/useTranslateDocument";
import { CameraControls } from "../../utils/camera-controls";

// const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

// data to display this map:
export const useMapData = create((set, get) => ({
  selectedPoint: null,
  dragDisabled: false,
  highlight: null,
  focus: null,
  zoom: 1,
  points: [],
  overlay: null,
  setOverlay: (overlay) => set({ overlay }),
  setPoints: (points) => set({ points }),
  setZoom: (zoom) => set({ zoom }),
  setSelectedPoint: (selectedPoint) => set({ selectedPoint }),
  updatePoint: (p) => {
    set({
      points: [
        ...get().points.map((tp) => {
          if (p.id === tp.id) {
            return { ...tp, ...p };
          }
          return tp;
        }),
      ],
    });
  },
  setIsDragging: (isDragging) => set({ isDragging }),
}));

export default function FullMap({ reload, children }) {
  let { user } = useUser();
  const camRef = useRef();
  let { data: clientData } = useTranslateDocument("clients/" + user.uid);

  let { points } = useMapData();

  let nav = useNavigate();

  return (
    <div className="FullMap">
      <Canvas
        orthographic
        camera={{ zoom: 60 }}
        style={{ height: 600 }}
        onCreated={({ gl }) => {
          gl.setClearColor("#f2f2f2");
        }}
      >
        <CameraControls ref={camRef}></CameraControls>

        <ambientLight></ambientLight>
        <directionalLight></directionalLight>
        {clientData && clientData.floorplan && (
          <Floor background={clientData.floorplan}></Floor>
        )}
        {points &&
          points.map((p) => {
            if (!p.point) {
              return null;
            }
            return (
              <Dot
                nav={nav}
                key={p.id}
                zoom={1}
                data={p}
                cam={camRef}
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopPropagation();
                  //onPointClicked(p);
                }}
                reload={reload}
              ></Dot>
            );
          })}
      </Canvas>
      {children}
    </div>
  );
}
/*
 {<Overlay
          selectedPoint={selectedPoint}
          updatePointPosition={(v) => {
            updateDocument("exhibits/" + selectedPoint.id, { point: v }).then(
              () => {
                reload();
              }
            );
          }}
        />}
*/
export function Floor({ background, onClick }) {
  let tex = useTexture(background);
  let floorRef = useRef();
  useEffect(() => {
    let aspect = tex.image.width / tex.image.height;

    floorRef.current.scale.set(aspect, 1, 1);
  }, [tex, floorRef]);

  return (
    <mesh ref={floorRef} position={[0.1, 0, 0]} onClick={onClick}>
      <planeBufferGeometry args={[10, 10, 1]}></planeBufferGeometry>
      <meshBasicMaterial map={tex}></meshBasicMaterial>
    </mesh>
  );
}

function Dot({ data, onClick, cam, reload }) {
  const isImageDot = data.style.thumbnail && data.style.type === "image";
  const isPOI = data.isPOI === true;
  let colorMap = useTexture(isImageDot ? data.style.thumbnail : "/dot.png");
  let [hover, setHover] = useState();
  let circlealpha = useTexture("/circlealpha.png");
  let dotalpha = useTexture("/dotalpha.png");
  let dotRef = useRef();
  let { camera } = useThree();
  let { setIsDragging, overlay, setOverlay, isDragging } = useMapData();
  const bind = useGesture({
    onPointerDown: ({ event }) => {
      event.stopPropagation();
      event.nativeEvent.stopPropagation();
    },

    onDrag: ({ event, delta, active, movement }) => {
      let distance = Math.sqrt(
        movement[0] * movement[0] + movement[1] * movement[1]
      );
      event.nativeEvent.stopPropagation();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      if (active && distance > 5) {
        setIsDragging(true);
        cam.current.enabled = false;
      }
      let z = camera.zoom;
      dotRef.current.position.add(
        new THREE.Vector3(delta[0] / z, -delta[1] / z, 0)
      );
      if (!active) {
        updateDocument("exhibits/" + data.id, {
          point: {
            x: dotRef.current.position.x,
            y: dotRef.current.position.y,
            z: 0.2,
          },
        }).then(() => {
          reload();
        });
        setTimeout(() => {
          setIsDragging(false);

          cam.current.enabled = true;
        }, 0);
      }
    },
  });

  useFrame(({ camera }) => {
    //adjust dot size with zoom
    if (dotRef.current) {
      const zoom = camera.zoom;
      dotRef.current.scale.set(
        ((0.25 * (data.style.size || 1)) / Math.max(zoom, 50)) * 60,
        ((0.25 * (data.style.size || 1)) / Math.max(zoom, 50)) * 60,
        ((0.25 * (data.style.size || 1)) / Math.max(zoom, 50)) * 60
      );
    }
  });

  const [{ opacity }] = useSpring(() => ({
    loop: true,
    config: { duration: 1000 },
    to: [{ opacity: 0.5 }, { opacity: 1 }],
    from: { opacity: 1 },
    reset: true,
  }));

  let thePos = data.point || {};
  return (
    <mesh
      ref={dotRef}
      {...bind()}
      transparent
      onClick={(e) => {
        if (!useMapData.getState().isDragging) {
          if (overlay === data.id) {
            setOverlay(null);
          } else {
            setOverlay(data.id);
            onClick(e);
          }
        }
      }}
      onPointerEnter={() => !isPOI && setHover(true)}
      onPointerLeave={() => !isPOI && setHover(false)}
      position={[thePos.x, thePos.y, thePos.z]}
      scale={0.25 * (data.style.size || 1)}
    >
      <planeBufferGeometry args={[1, 1, 1]}></planeBufferGeometry>
      {isImageDot && (
        <a.meshBasicMaterial
          transparent
          map={colorMap}
          alphaMap={dotalpha}
          opacity={overlay === data.id ? opacity : 1}
        ></a.meshBasicMaterial>
      )}
      {data.style.type === "dot" && (
        <a.meshBasicMaterial
          transparent
          color={data.style.color || "red"}
          alphaMap={dotalpha}
          opacity={overlay === data.id ? opacity : 1}
        ></a.meshBasicMaterial>
      )}
      {data.style.type === "circle" && (
        <a.meshBasicMaterial
          transparent
          color={data.style.color || "red"}
          alphaMap={circlealpha}
          opacity={overlay === data.id ? opacity : 1}
        ></a.meshBasicMaterial>
      )}
      {hover && !isDragging && (!overlay || overlay !== data.id) && (
        <Html position={[0.5, 0.5, 0]} style={{ zIndex: 10 }}>
          <div className="dotPopup">
            <div className="popupName">{data.name}</div>
            <img
              alt="thumb"
              className="popupImage"
              src={
                data.thumbnail ||
                (data.images && data.images[0]) ||
                "https://via.placeholder.com/60"
              }
            ></img>
          </div>
        </Html>
      )}
    </mesh>
  );
}

export function DumnDot({ data, onClick }) {
  const isImageDot = data.style.thumbnail && data.style.type === "image";
  let [hover, setHover] = useState();
  let colorMap = useTexture(isImageDot ? data.style.thumbnail : "/dot.png");

  let circlealpha = useTexture("/circlealpha.png");
  let dotalpha = useTexture("/dotalpha.png");
  let dotRef = useRef();
  let thePos = data.point || {};
  return (
    <mesh
      ref={dotRef}
      transparent
      onClick={(e) => {
        if (!useMapData.getState().isDragging) {
          onClick();
        }
      }}
      onPointerEnter={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      position={[thePos.x, thePos.y, thePos.z]}
      scale={0.25 * (data.style.size || 1)}
    >
      <planeBufferGeometry args={[1, 1, 1]}></planeBufferGeometry>
      {isImageDot && (
        <a.meshBasicMaterial
          transparent
          map={colorMap}
          alphaMap={dotalpha}
          opacity={1}
        ></a.meshBasicMaterial>
      )}
      {data.style.type === "dot" && (
        <a.meshBasicMaterial
          transparent
          color={data.style.color || "red"}
          alphaMap={dotalpha}
          opacity={1}
        ></a.meshBasicMaterial>
      )}
      {data.style.type === "circle" && (
        <a.meshBasicMaterial
          transparent
          color={data.style.color || "red"}
          alphaMap={circlealpha}
          opacity={1}
        ></a.meshBasicMaterial>
      )}
      {hover && (
        <Html position={[0.5, 0.5, 0]} style={{ zIndex: 10 }}>
          <div className="dotPopup">
            <div className="popupName">{data.name}</div>
            <img
              alt="thumb"
              className="popupImage"
              src={
                data.thumbnail ||
                (data.images && data.images[0]) ||
                "https://via.placeholder.com/60"
              }
            ></img>
          </div>
        </Html>
      )}
    </mesh>
  );
}
