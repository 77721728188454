import React from "react";
import Label from "../Label/Label";
import "./StyledInput.scss";

export default React.forwardRef(
  (
    {
      value,
      onChange,
      label,
      placeholder,
      type = "text",
      onEnter = () => {},
      ...props
    },
    ref
  ) => {
    return (
      <div className="StyledInput">
        {label && <Label>{label}</Label>}
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter();
            }
          }}
          ref={ref}
          type={type}
          className="styledInput"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
      </div>
    );
  }
);
