import {
  Center,
  Environment,
  Html,
  OrbitControls,
  PerspectiveCamera,
  useFBX,
  useGLTF,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense, useCallback, useEffect, useRef } from "react";
import ImageUploader from "../ImageUploader/ImageUploader";
import Label from "../Label/Label";
import "./ThreeDeeEditZone.scss";
import { MdTextRotationAngleup } from "react-icons/md";
import { FaCamera, FaRecycle, FaTrash, FaTree } from "react-icons/fa";
import { BsFillLightbulbFill } from "react-icons/bs";
import { GiResize } from "react-icons/gi";
import { HexColorPicker } from "react-colorful";
import Spacer from "../Spacer/Spacer";
import StyledButton from "../StyledButton/StyledButton";
import Slider from "rc-slider";

export default function ThreeDeeEditZone({ value, onChange }) {
  let controller = useRef();
  let cameraRef = useRef();
  const setField = useCallback(
    (k, v) => {
      onChange({ ...value, [k]: v });
    },
    [value, onChange]
  );

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        node.setPolarAngle(1);
        node.setAzimuthalAngle(-0.5);
        node.setPolarAngle(value.angle);
      }
      controller.current = node;
    },
    [value.angle]
  );
  useEffect(() => {
    controller.current && controller.current.setPolarAngle(value.angle);
  }, [value.angle]);
  let glRef = useRef();
  useEffect(() => {
    glRef.current && glRef.current.setClearColor(value.color);
  }, [value.color]);
  console.log(value);
  return (
    <div className="ThreeDeeEditZone">
      <Label>3D Modell</Label>
      {!value.file && (
        <div className="threeDeeUpload">
          <ImageUploader
            accept={false}
            value={value.file}
            onChange={(v, name) => {
              onChange({ ...value, file: v, filename: name });
            }}
            label={"3D Modell hier ablegen"}
          ></ImageUploader>
        </div>
      )}
      {value.file && (
        <div className="edit3DZone">
          <Canvas
            style={{ height: 600, width: 300 }}
            linear
            onCreated={({ gl }) => {
              if (value.color) {
                gl.setClearColor(value.color);
              }
              glRef.current = gl;
            }}
          >
            <Suspense
              fallback={
                <Html style={{ color: "#f2f2f2", fontWeight: "bold" }}>
                  Loading
                </Html>
              }
            >
              <PerspectiveCamera
                position={[0, 0, 4]}
                fov={value.fov}
                makeDefault
                ref={cameraRef}
              ></PerspectiveCamera>
              <Center>
                {value && value.filename && value.filename.toLowerCase().endsWith(".gltf") && (
                  <GLTFModel src={value.file} scale={value.size}></GLTFModel>
                )}
                {value && value.filename && value.filename.toLowerCase().endsWith(".fbx") && (
                  <FBXModel src={value.file} scale={value.size}></FBXModel>
                )}
              </Center>
              <OrbitControls
                enableZoom={false}
                enablePan={false}
                enableRotate={false}
                autoRotateSpeed={value.rotateSpeed}
                camera={cameraRef.current}
                autoRotate
                ref={measuredRef}
              ></OrbitControls>
              <ambientLight intensity={0.2}></ambientLight>
              <pointLight
                position={[-15, 25, -5]}
                intensity={value.light * 0.1}
                castShadow
                shadow-mapSize-height={512}
                shadow-mapSize-width={512}
                shadow-radius={10}
                shadow-bias={-0.0001}
              />
              <pointLight
                position={[-15, 15, -15]}
                intensity={value.light * 0.1}
                castShadow
                shadow-mapSize-height={512}
                shadow-mapSize-width={512}
                shadow-radius={10}
                shadow-bias={-0.0001}
              />
              <pointLight
                position={[0, 15, -15]}
                intensity={value.light * 0.7}
                castShadow
                shadow-mapSize-height={1024}
                shadow-mapSize-width={1024}
                shadow-radius={20}
                shadow-bias={-0.0001}
              />
              <pointLight
                position={[0, 15, 15]}
                intensity={value.light * 0.7}
                castShadow
                shadow-mapSize-height={1024}
                shadow-mapSize-width={1024}
                shadow-radius={20}
                shadow-bias={-0.0001}
              />
              {value.env && (
                <Environment
                  background={"only"}
                  files={value.env}
                ></Environment>
              )}
            </Suspense>
          </Canvas>

          <div className="editArea">
            <Label>
              <MdTextRotationAngleup></MdTextRotationAngleup> Winkel
            </Label>
            <Slider
              value={value.angle}
              onChange={(e) => setField("angle", parseFloat(e))}
              min={0}
              max={Math.PI}
              step={0.05}
            ></Slider>
            <Label>
              <FaRecycle></FaRecycle> Drehgeschwindigkeit
            </Label>
            <Slider
              value={value.rotateSpeed}
              onChange={(e) => setField("rotateSpeed", parseFloat(e))}
              min={0}
              max={2}
              step={0.05}
            ></Slider>
            <Label>
              <FaCamera></FaCamera> FOV
            </Label>
            <Slider
              value={value.fov}
              onChange={(e) => setField("fov", parseFloat(e))}
              min={24}
              max={90}
              step={1}
            ></Slider>
            <Label>
              <BsFillLightbulbFill></BsFillLightbulbFill> Licht
            </Label>
            <Slider
              value={value.light}
              onChange={(e) => setField("light", parseFloat(e))}
              min={0}
              max={3}
              step={0.05}
            ></Slider>
            <Label>
              <GiResize></GiResize> Größe des Objekts
            </Label>
            <Slider
              value={value.size}
              onChange={(e) => setField("size", parseFloat(e))}
              type="range"
              min={0}
              max={2}
              step={0.05}
            ></Slider>
            <Label>
              <FaTree></FaTree> Umgebung
            </Label>
            <EnvSelector
              onChange={(v) => setField("env", v)}
              value={value.env}
            ></EnvSelector>
            {!value.env && (
              <>
                <Spacer h={24}></Spacer>
                <HexColorPicker
                  color={value.color}
                  onChange={(v) => setField("color", v)}
                ></HexColorPicker>
              </>
            )}
            <Spacer h={24}></Spacer>
            <StyledButton color="grey" onClick={() => setField("file", "")}>
              3D Modell löschen <Spacer w={12}></Spacer> <FaTrash></FaTrash>
            </StyledButton>
          </div>
        </div>
      )}
    </div>
  );
}

function GLTFModel({ scale, src }) {
  let model = useGLTF(src);
  return (
    <group scale={0.05 * scale}>
      <primitive object={model.scene} />
    </group>
  );
}

function FBXModel({ scale, src }) {
  let fbx = useFBX(src);
  return (
    <group scale={0.05 * scale}>
      <primitive object={fbx} />
    </group>
  );
}

function EnvSelector({ value, onChange }) {
  let results = [
    { id: 1, url: "/env1.hdr", name: "Outdoor" },
    { id: 2, url: "/env2.hdr", name: "Halle" },
  ];
  return (
    <select value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="">Einfarbig</option>
      {results.map((r) => {
        return (
          <option key={r.id} value={r.url}>
            {r.name}
          </option>
        );
      })}
    </select>
  );
}
