import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import TourForm from "../../atoms/TourForm/TourForm";
import useTranslateDocument from "../../hooks/useTranslateDocument";
import "./EditTourScreen.scss";

export default function EditTourScreen() {
  let { tourId } = useParams();
  let {
    data: tourData,
    updateDocument,
    updateStatus,
    setField,
  } = useTranslateDocument("/tours/" + tourId);
  return (
    <Layout className="EditTourScreen" title="Führung bearbeiten">
      <Spacer h={12}></Spacer>
      <TourForm
        value={tourData}
        status={updateStatus}
        setField={setField}
        onComplete={() => {
          updateDocument().then(() => {});
        }}
      ></TourForm>
    </Layout>
  );
}
