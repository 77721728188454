import Slider from "rc-slider";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { FaPlus, FaStar, FaTrash } from "react-icons/fa";
import ImageUploader from "../ImageUploader/ImageUploader";
import KeyValueForm from "../KeyValueForm/KeyValueForm";
import Label from "../Label/Label";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import Spacer from "../Spacer/Spacer";
import StyledButton from "../StyledButton/StyledButton";
import StyledInput from "../StyledInput/StyledInput";
import StyledTextarea from "../StyledTextarea/StyledTextarea";
import ThreeDeeEditZone from "../ThreeDeeEditZone/ThreeDeeEditZone";
import { getFunctions, httpsCallable } from "firebase/functions";
import "./ExhibitForm.scss";
import Loading from "react-loading";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

export default function ExhibitForm({
  onComplete,
  status,
  setField,
  value,
  children,
  secondSave,
}) {
  const f = (key) => {
    return {
      value: value[key] || "",
      onChange: (v) => {
        setField(key, v);
      },
    };
  };
  return (
    <div className="ExhibitForm">
      <LanguageSwitch>
        <div className="row">
          <div className="sidebar">
            <div className="imageZone box shadow">
              <Label>Bilder</Label>
              <ExhibitImageEdit {...f("images")}></ExhibitImageEdit>
            </div>
            <Spacer h={12}></Spacer>
            <DotStyleEditor {...f("style")}></DotStyleEditor>
          </div>
          <div className="basicInfo">
            {secondSave && false && (
              <>
                <Spacer h={0}></Spacer>
                <StyledButton onClick={onComplete} status={status}>
                  Speichern
                </StyledButton>
              </>
            )}
            <div className="box shadow">
              <StyledInput
                placeholder="Name des Exponats"
                {...f("name")}
                label={"Name des Exponats"}
              ></StyledInput>
              <Spacer h={24}></Spacer>
              <StyledTextarea
                {...f("description")}
                label="Beschreibung"
              ></StyledTextarea>
            </div>
            <div className="box shadow">
              <KeyValueForm
                label={"Datenpunkte"}
                {...f("infos")}
              ></KeyValueForm>
            </div>
            <div className="box shadow">
              <StyledInput
                placeholder="https://youtube.com/watch?v=...."
                {...f("video")}
                label={"Video (Youtube-Link)"}
              ></StyledInput>
            </div>
            <div className="box shadow">
              <AudioEditor {...f("audio")}></AudioEditor>
            </div>
            <ErrorBoundary
              key={f("model").value.file}
              errorUi={
                <div>
                  <h1>Ein Fehler ist aufgetreten</h1>
                  <StyledButton
                    onClick={() => {
                      f("model").onChange({
                        size: 1,
                        file: "",
                        angle: 1.2,
                        rotateSpeed: 0.5,
                        fov: 45,
                        light: 1,
                        environment: null,
                        color: "#444444",
                      });
                    }}
                  >
                    Zurücksetzen
                  </StyledButton>
                </div>
              }
            >
              <div className="box shadow">
                <ThreeDeeEditZone {...f("model")}></ThreeDeeEditZone>
              </div>
            </ErrorBoundary>
          </div>
        </div>
        <Spacer h={24}></Spacer>
        {children}
        <Spacer h={8}></Spacer>
        <StyledButton onClick={onComplete} status={status}>
          Speichern
        </StyledButton>
      </LanguageSwitch>
    </div>
  );
}

function ExhibitImageEdit({ value, onChange }) {
  return !value || value.length < 1 ? (
    <ImageUploader
      label="Bilder ablegen oder hier klicken."
      multiple
      value={value}
      onChange={onChange}
    ></ImageUploader>
  ) : (
    <div>
      <div className="currentMainImage">
        <img alt="Exhibit" src={value[0]}></img>
        <div
          className="removeImageButton"
          onClick={() => {
            onChange(value.filter((ii) => value[0] !== ii));
          }}
        >
          <FaTrash></FaTrash>
        </div>
      </div>
      <div className="otherImages">
        {value.slice(1).map((v, i) => {
          return (
            <div className="smallImageWrap" key={v}>
              <img src={v} alt="exhibit"></img>
              <div
                className="removeImageButton"
                onClick={() => {
                  onChange(value.filter((ii) => v !== ii));
                }}
              >
                <FaTrash></FaTrash>
              </div>
              <div
                className="starImageButton"
                onClick={() => {
                  onChange([v, ...value.filter((ii) => v !== ii)]);
                }}
              >
                <FaStar></FaStar>
              </div>
            </div>
          );
        })}
        <div className="smallUploader">
          <ImageUploader
            doneText={<FaPlus></FaPlus>}
            label={<FaPlus></FaPlus>}
            multiple
            value={value}
            onChange={onChange}
          ></ImageUploader>
        </div>
      </div>
    </div>
  );
}

function DotStyleEditor({ value = {}, onChange }) {
  return (
    <div className="dotStyleEditor box shadow">
      <Label>Aussehen der Markierung</Label>
      <Spacer h={12}></Spacer>
      <div className="pointTypes">
        <div
          className={"pt " + (value.type === "circle" ? "selected " : " ")}
          onClick={() => {
            onChange({ ...value, type: "circle" });
          }}
        >
          <div className="img">
            <div style={{ borderColor: value.color }} className="circle"></div>
          </div>
          <div className="text">Punkt</div>
        </div>
        <div
          className={"pt " + (value.type === "dot" ? "selected " : " ")}
          onClick={() => {
            onChange({ ...value, type: "dot" });
          }}
        >
          <div className="img">
            <div style={{ backgroundColor: value.color }} className="dot"></div>
          </div>
          <div className="text">Kreis</div>
        </div>
        <div className={"pt " + (value.type === "image" ? "selected " : " ")}>
          <div className="thumbHolder">
            <ThumbnailSelector
              active={value.type === "image"}
              value={value.thumbnail}
              onChange={(thumb) => {
                if (thumb) {
                  onChange({ ...value, type: "image", thumbnail: thumb });
                } else {
                  onChange({ ...value, type: "dot", thumbnail: "" });
                }
              }}
            ></ThumbnailSelector>
          </div>
          <div className="text">Thumbnail</div>
        </div>
      </div>
      {value.type !== "image" && (
        <>
          <Spacer h={12}></Spacer>
          <Label>Farbe</Label>
          <HexColorPicker
            color={value.color}
            onChange={(v) => {
              onChange({ ...value, color: v });
            }}
            style={{ height: 100, width: "auto" }}
          ></HexColorPicker>
        </>
      )}
      <Spacer h={12}></Spacer>
      <Label>Größe</Label>
      <div className="sliderHolder">
        <Slider
          min={1}
          max={8}
          step={0.1}
          value={value.size}
          onChange={(v) => {
            onChange({ ...value, size: v });
          }}
        ></Slider>
        <Spacer w={12}></Spacer>
        <input
          className="sizeInput"
          value={value.size}
          type="number"
          onChange={(e) => {
            onChange({ ...value, size: e.target.value });
          }}
        ></input>
      </div>
      <Spacer h={12}></Spacer>
      <Label>Vorschau</Label>
      <div className="preview" style={{ zoom: value.size / 4 }}>
        {value.type === "dot" && (
          <div style={{ backgroundColor: value.color }} className="dot"></div>
        )}
        {value.type === "circle" && (
          <div style={{ borderColor: value.color }} className="circle"></div>
        )}
        {value.type === "image" && (
          <img
            alt="thumbnail"
            className="thumbPreview"
            src={value.thumbnail}
          ></img>
        )}
      </div>
    </div>
  );
}

export function ThumbnailSelector({
  value,
  onChange = () => {},
  active = false,
}) {
  let [status, setStatus] = useState("start");
  const functions = getFunctions();
  const helloWorld = httpsCallable(functions, "makeThumb");
  if (status === "loading") {
    return (
      <div className="pointType">
        <Loading color="orange" type="spin" width={50} height={50}></Loading>
      </div>
    );
  }
  if (value) {
    return (
      <div className="thumbWrapper">
        <img
          alt="thumbnail"
          className="thumbPreview"
          src={value}
          onClick={() => {
            if (active) {
            } else {
              onChange(value);
            }
          }}
        ></img>
        <div
          className="deleteThumbButton"
          onClick={() => {
            onChange("");
          }}
        >
          <FaTrash></FaTrash>
        </div>
      </div>
    );
  }

  return (
    <div className="miniUpload">
      <ImageUploader
        label={"+"}
        onChange={(url) => {
          setStatus("loading");
          let fileName = url.split("/")[7].split("?")[0];
          helloWorld({
            fileName,
          }).then((result) => {
            setStatus("done");

            onChange(result.data.url);
          });
        }}
      />
    </div>
  );
}

function AudioEditor({ value, onChange }) {
  return (
    <div className="audioEditor">
      <Label>Audioguide</Label>
      {!value && (
        <div className="audioUlWrap">
          <ImageUploader
            accept={false}
            onChange={(url) => onChange(url)}
            label={"Audiodatei hier ablegen"}
          ></ImageUploader>
        </div>
      )}
      {value && (
        <div className="currentAudioFile">
          <audio src={value} controls></audio>
          <div className="deleteAudioButton" onClick={() => onChange(null)}>
            <FaTrash></FaTrash>
          </div>
        </div>
      )}
    </div>
  );
}
