import React from "react";
import Spacer from "../Spacer/Spacer";
import "./TextButton.scss";

export default function TextButton({ children, icon, onClick = () => {} }) {
  return (
    <div className="TextButton shadow" onClick={() => onClick()}>
      {children && (
        <>
          {children}
          <Spacer w={24}></Spacer>
        </>
      )}
      <div className="icon">{icon}</div>
    </div>
  );
}
