import React from "react";
import Loading from "react-loading";
import "./FBLoader.scss";

export default function FBLoader({ status, children }) {
  if (status === "loading") {
    return (
      <div className="FBLoader">
        <Loading color="orange" type="spin"></Loading>
      </div>
    );
  }
  return children;
}
