import React from "react";

import SingleImageUploader from "../SingleImageUploader/SingleImageUploader";
import Spacer from "../Spacer/Spacer";
import StyledButton from "../StyledButton/StyledButton";
import StyledInput from "../StyledInput/StyledInput";
import "./TourForm.scss";

import StyledTextarea from "../StyledTextarea/StyledTextarea";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";

export default function TourForm({ value = {}, onComplete, status, setField }) {
  const f = (key) => {
    return {
      value: value[key] || "",
      onChange: (v) => {
        setField(key, v);
      },
    };
  };

  return (
    <LanguageSwitch>
      <div className="TourForm">
        <div className="box shadow">
          <StyledInput {...f("name")} label="Name der Tour"></StyledInput>
          <StyledTextarea {...f("text")} label="Beschreibung"></StyledTextarea>
        </div>
        <Spacer h={12}></Spacer>
        <div className="box shadow flex">
          <div className="flex1">
            <StyledInput
              {...f("duration")}
              placeholder={"30 min"}
              label="Geschätzter Zeitaufwand"
            ></StyledInput>
          </div>
          <Spacer w={12}></Spacer>
          <div className="flex1">
            <StyledInput
              {...f("age")}
              placeholder={"12+"}
              label="Altersempfehlung"
            ></StyledInput>
          </div>
        </div>
        <Spacer h={12}></Spacer>
        <div className="box shadow">
          <SingleImageUploader
            {...f("image")}
            label="Bild für die Tour"
          ></SingleImageUploader>
        </div>
        <Spacer h={24}></Spacer>
        <label>
          <input
            checked={value.isTreasureHunt}
            onChange={() => setField("isTreasureHunt", !value.isTreasureHunt)}
            type="checkbox"
          ></input>
          Schnitzeljagd
        </label>
        <br></br>
        <label>
          <input
            checked={value.isEnabled}
            onChange={() => setField("isEnabled", !value.isEnabled)}
            type="checkbox"
          ></input>
          Veröffentlichen
        </label>

        <Spacer h={24}></Spacer>
        <StyledButton
          status={status}
          onClick={() => {
            onComplete(value);
          }}
        >
          Speichern
        </StyledButton>
      </div>
    </LanguageSwitch>
  );
}
