import React, { useState } from "react";
import Label from "../Label/Label";
import Spacer from "../Spacer/Spacer";
import StyledInput from "../StyledInput/StyledInput";

import "./PointSearchList.scss";

export default function PointSearchList({ points, onPointClicked }) {
  let [search, setSearch] = useState("");
  const pointsToAdd = points
    .filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))
    .filter((p) => !p.isPOI)
    .sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div className="PointSearchList">
      {pointsToAdd.length > 0 && (
        <>
          <Label>Neue Punkte zur Tour hinzufügen</Label>
          <Spacer h={12}></Spacer>
          <StyledInput
            placeholder="Suche"
            type="text"
            value={search}
            onChange={(e) => setSearch(e)}
          ></StyledInput>
        </>
      )}
      <div className="scrollArea">
        {pointsToAdd.map((r) => {
          return (
            <div
              key={r.id}
              onClick={() => {
                onPointClicked(r);
              }}
              className="pointRow"
            >
              <img
                alt="thumbnail"
                className="pointImage"
                src={
                  (r.thumbnail && r.thumbnail) ||
                  (r.images && r.images[0]) ||
                  "https://via.placeholder.com/60"
                }
              ></img>
              {r.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}
