import React from "react";
import "./LanguageSwitch.scss";

import { useLanguage } from "../../hooks/useLanguage";
import useTranslateDocument from "../../hooks/useTranslateDocument";
import { useUser } from "../../hooks/useUser";

export default function LanguageSwitch({ children }) {
  let { language, setLanguage } = useLanguage();
  let { user } = useUser();
  let {
    data: { languages },
  } = useTranslateDocument("/clients/" + user.uid);
  
  return (
    <>
      <div className="LanguageSwitch">
        <div className="top">
          {languages &&
            languages.map((l) => {
              return (
                <div
                  key={l}
                  className={
                    "lang shadow " + (language === l ? "selected" : "")
                  }
                  onClick={() => setLanguage(l)}
                >
                  <div className="flag">
                    <img
                      alt={"Flag " + l}
                      src={"/flags/" + l.toLowerCase() + ".svg"}
                    ></img>
                  </div>
                  <div className="name">{l}</div>
                </div>
              );
            })}
        </div>
      </div>
      {children}
    </>
  );
}
