import React from "react";

import { useUser } from "../../hooks/useUser";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";

import "./UnlockCodes.scss";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { QRCode } from "react-qrcode-logo";
import useTranslateDocument from "../../hooks/useTranslateDocument";
import Label from "../../atoms/Label/Label";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function UnlockCodes() {
  const { user } = useUser();
  let { data } = useTranslateDocument("clients/" + user.uid);
  const [uses, setUses] = React.useState("");
  const [lastCode, setLastCode] = React.useState();
  const nav = useNavigate();
  return (
    <div className="unlockCodes">
      {!lastCode && (
        <>
          <div className="title">
            <div onClick={() => nav("/")} className="backButton">
              <FaArrowLeft></FaArrowLeft>
            </div>
            Avemus Freischaltcode
          </div>
          <div className="box shadow addRoom">
            <Label>Anzahl der Besucher</Label>
            <div className="quickOptions">
              <div
                className="quickOption"
                onClick={() => {
                  setLastCode(generateUnlockCode({ user, uses: 1 }));
                  setUses("");
                }}
              >
                1
              </div>
              <div
                className="quickOption"
                onClick={() => {
                  setLastCode(generateUnlockCode({ user, uses: 2 }));
                  setUses("");
                }}
              >
                2
              </div>
              <div
                className="quickOption"
                onClick={() => {
                  setLastCode(generateUnlockCode({ user, uses: 5 }));
                  setUses("");
                }}
              >
                5
              </div>
              <div
                className="quickOption"
                onClick={() => {
                  setLastCode(generateUnlockCode({ user, uses: 10 }));
                  setUses("");
                }}
              >
                10
              </div>
            </div>
            <input
              className="usesInput"
              type="text"
              placeholder="... andere Anzahl"
              value={uses}
              onChange={(e) => setUses(e.target.value)}
            />
          </div>
          <div style={{ paddingLeft: 12, paddingRight: 12 }}>
            <StyledButton
              onClick={() => {
                setLastCode(generateUnlockCode({ user, uses: uses || 1 }));
                setUses("");
              }}
            >
              Freischaltcode generieren
            </StyledButton>
          </div>
        </>
      )}
      {data.subdomain && lastCode && (
        <>
          <div className="title">
            <div onClick={() => nav("/")} className="backButton">
              <FaArrowLeft></FaArrowLeft>
            </div>
            Avemus Freischaltcode
          </div>
          <div className="qrHolder">
            <QRCode
              size={300}
              value={
                "https://" +
                data.subdomain +
                ".avemus.app/unlock/" +
                lastCode.code
              }
            ></QRCode>
          </div>

          <div className="code">{lastCode.code}</div>
          <div className="uses">Gültig für {lastCode.uses} Besucher</div>
          <div className="flex1"></div>
          <div style={{ paddingLeft: 12, paddingRight: 12 }}>
            <StyledButton
              onClick={() => {
                setLastCode(null);
              }}
            >
              Fertig
            </StyledButton>
          </div>
        </>
      )}
    </div>
  );
}

function generateUnlockCode({ user, uses = 1 }) {
  const shortCode = makeid(6);
  let code = {
    code: shortCode,
    uses: parseInt(uses) || 1,
    used: 0,
    user: user.uid,
    id: user.uid + "_" + shortCode,
    created: serverTimestamp(),
  };
  const docRef = doc(db, "unlockCodes", code.user + "_" + code.code);
  setDoc(docRef, code);
  return code;
}

function makeid(length) {
  let result = "";
  const characters = "123456789abcdefghjkmnpqrstuvwxyz";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
