import React from "react";
import { useNavigate } from "react-router-dom";
import ExhibitForm from "../../atoms/ExhibitForm/ExhibitForm";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import {
  query,
  orderBy,
  limit,
  doc,
  getDocs,
  collection,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import useCreateTranslateableDocument from "../../hooks/useCreateTranslateableDocument";
import "./CreateExhibit.scss";
import { useUser } from "../../hooks/useUser";

export default function CreateExhibit() {
  const nav = useNavigate();
  const { user } = useUser();
  let { create, status, data, setField } = useCreateTranslateableDocument(
    {
      name: { t: true, default: "" },
      infos: { t: true, default: {} },
      size: { t: false, default: "" },
      description: { t: true, default: "" },
      video: { t: true, default: "" },
      audio: { t: true, default: "" },
      style: {
        t: false,
        default: { size: 2, color: "#ff0000", type: "dot", thumbnail: "" },
      },

      model: {
        t: false,
        default: {
          size: 1,
          file: "",
          angle: 1.2,
          rotateSpeed: 0.5,
          fov: 45,
          light: 1,
          environment: null,
          color: "#444444",
        },
      },
      images: { t: false, default: [] },
    },
    "/exhibits"
  );

  return (
    <Layout className="CreateExhibit" title={"Neues Exponat erstellen"}>
      <Spacer h={12}></Spacer>
      <ExhibitForm
        status={status}
        setField={setField}
        value={data}
        onComplete={() =>
          create({ isPOI: false }).then((exhibitId) => {
            //assign ID
            const q = query(
              collection(db, "shortCodes/"),
              where("user", "==", user.uid),
              orderBy("shortId", "desc"),
              limit(1)
            );
            getDocs(q).then((querySnapshot) => {
              let nextId = 1;
              if (querySnapshot.size > 0) {
                let lastId = parseInt(querySnapshot.docs[0].data().shortId);
                nextId = lastId + 1;
              }
              nextId = nextId.toString().padStart(3, "0");
              const docRef = doc(collection(db, "shortCodes/"));
              setDoc(docRef, {
                id: docRef.id,
                shortId: nextId,
                targetId: exhibitId,
                type: "exhibit",
                user: user.uid,
              }).then(() => {
                nav("/collection");
              });
            });
          })
        }
      ></ExhibitForm>
    </Layout>
  );
}
