import React, { useEffect, useRef } from "react";
import "./QrMultimaker.scss";

import { QRCode } from "react-qrcode-logo";
import { saveAs } from "file-saver";

import JSZip from "jszip";
import Loading from "react-loading";
import StyledButton from "../StyledButton/StyledButton";
import Spacer from "../Spacer/Spacer";
import { useUser } from "../../hooks/useUser";
import useTranslateDocument from "../../hooks/useTranslateDocument";
const factor = 1;
const cornerStyles = {
  rund: [10 * factor, 10 * factor, 10 * factor, 10 * factor],
  eckig: [0, 0, 0, 0],
  tropfen: [
    [10 * factor, 10 * factor, 0, 10 * factor],
    [10 * factor, 10 * factor, 10 * factor, 0],
    [10 * factor, 0, 10 * factor, 10 * factor],
  ],
};
export default function QrMultimaker({
  from = 100,
  to = 200,
  arr = new Array(to - from + 1).fill(0),
  qrStyle,
}) {
  const qrCodeRef = useRef([]);
  // function to download the QR code
  const [loading, setLoading] = React.useState(true);

  const { user } = useUser();

  let { data } = useTranslateDocument("/clients/" + user.uid);

  //after 3 seconds, set loading to false
  useEffect(() => {
    if (loading) {
      let tid = setTimeout(() => {
        setLoading(false);
      }, 3000);
      return () => {
        clearTimeout(tid);
      };
    }
  }, [loading]);

  qrCodeRef.current = [];
  return (
    <div className="QrMultimaker">
      {loading && <Loading type="spin"></Loading>}
      {!loading && (
        <>
          <Spacer h={24}></Spacer>
          <StyledButton
            onClick={() => {
              arr.forEach((item, index) => {
                const ctx =
                  qrCodeRef.current[index].canvas.current.getContext("2d");
                ctx.font = "bold " + 20 * factor + "px sans-serif";
                ctx.fillStyle = "black";
                ctx.textAlign = "center";
                ctx.textRendering = "optimizeLegibility";
                //bold
                ctx.fontWeight = "bold";
                ctx.fillText(
                  "#" + (index + from).toString().padStart(3, "0"),
                  115 * factor,
                  228 * factor
                );
              });
              var zip = new JSZip();

              arr.forEach((item, index) => {
                zip.file(
                  "QR_Code" + (index + from) + ".png",
                  qrCodeRef.current[index].canvas.current
                    .toDataURL()
                    .replace(/^data:image\/(png|jpg);base64,/, ""),
                  { base64: true }
                );
              });
              zip.generateAsync({ type: "blob" }).then(function (content) {
                // see FileSaver.js
                saveAs(content, "avemus_qr_codes.zip");
              });
            }}
          >
            <Spacer w={24}></Spacer>
            QR Codes herunterladen
            <Spacer w={24}></Spacer>
          </StyledButton>

          <Spacer h={24}></Spacer>
        </>
      )}
      <div style={{ display: "none" }}>
        {arr.map((item, index) => {
          return (
            <QRCode
              ref={(el) => (qrCodeRef.current[index] = el)}
              bgColor={"#ffffff00"}
              fgColor={qrStyle.color}
              value={
                "https://" +
                data.subdomain +
                "avemus.app/shortLink/" +
                (index + from)
              }
              enableCORS
              size={190 * factor}
              quietZone={20 * factor}
              qrStyle={qrStyle.type}
              ecLevel={"H"}
              removeQrCodeBehindLogo
              eyeRadius={cornerStyles[qrStyle.cornerStyle]}
              logoImage={qrStyle.image}
              logoHeight={qrStyle.size}
              logoWidth={qrStyle.size}
            ></QRCode>
          );
        })}
      </div>
    </div>
  );
}
