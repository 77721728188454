import React, { useCallback, useState } from "react";
import Layout from "../../atoms/Layout/Layout";
import "./Login.scss";
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import app from "../../firebase";
import StyledInput from "../../atoms/StyledInput/StyledInput";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import Spacer from "../../atoms/Spacer/Spacer";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const auth = getAuth(app);
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let [status, setStatus] = useState("start");
  let nav = useNavigate();

  let login = useCallback(() => {
    setStatus("loading");
    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithEmailAndPassword(auth, username, password)
        .then((userCredential) => {
          setStatus("done");
          nav("/");

          // Signed in
          //const user = userCredential.user;
        })
        .catch((error) => {
          setStatus("error");
          //const errorMessage = error.message;
          
        });
    });
  }, [setStatus, username, password, auth, nav]);
  return (
    <Layout className="Login">
      <div className="title">Login</div>
      <Spacer h={12}></Spacer>
      <StyledInput
        label="Benutzername"
        onEnter={() => {
          login();
        }}
        onChange={(v) => setUsername(v)}
        value={username}
      ></StyledInput>
      <Spacer h={12}></Spacer>
      <StyledInput
        onEnter={() => {
          login();
        }}
        onChange={(v) => setPassword(v)}
        type="password"
        label="Passwort"
        value={password}
      ></StyledInput>
      <Spacer h={12}></Spacer>
      <StyledButton
        status={status}
        onClick={() => {
          login();
        }}
      >
        Login
      </StyledButton>
    </Layout>
  );
}
