import React from "react";

import { useNavigate } from "react-router-dom";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import SingleImageUploader from "../SingleImageUploader/SingleImageUploader";
import Spacer from "../Spacer/Spacer";
import StyledButton from "../StyledButton/StyledButton";
import StyledInput from "../StyledInput/StyledInput";
import StyledTextarea from "../StyledTextarea/StyledTextarea";
import "./ClientForm.scss";

export default function ClientForm({
  status,
  onComplete,
  value = {},
  setField,
}) {
  const nav = useNavigate();
  const f = (key) => {
    return {
      value: value[key] || "",
      onChange: (v) => {
        setField(key, v);
      },
    };
  };
  return (
    <LanguageSwitch>
      <div className="ClientForm">
        <div className="box shadow">
          <StyledInput label={"Ihr Name"} {...f("name")}></StyledInput>

          <Spacer h={24}></Spacer>

          <StyledTextarea
            {...f("description")}
            label="Beschreibung"
          ></StyledTextarea>
        </div>
        <Spacer h={24}></Spacer>
        <div className="flex" style={{ display: "flex", gap: 12 }}>
          <div className="flex1 box shadow">
            <SingleImageUploader
              label={"Logo"}
              ulLabel={"Logo hier ablegen"}
              {...f("logo")}
            />
          </div>
          <div className="flex1 box shadow">
            <SingleImageUploader
              ulLabel={"Grundriss hier ablegen"}
              label={"Grundriss Ihrer Einrichtung"}
              {...f("floorplan")}
            />
          </div>
          <div className="flex1 box shadow">
            <SingleImageUploader
              ulLabel={"Willkommensbild hier ablegen"}
              label={"Willkommenbild"}
              {...f("image")}
            />
          </div>
        </div>
        <Spacer h={24}></Spacer>
        <StyledButton status={status} onClick={() => onComplete()}>
          Speichern
        </StyledButton>
        <Spacer h={8}></Spacer>
        <StyledButton color="grey" onClick={() => nav("/")}>
          Abbrechen
        </StyledButton>
      </div>
    </LanguageSwitch>
  );
}
