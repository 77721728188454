import create from "zustand";

export const useLanguage = create((set, get) => ({
  language: "DE",
  setLanguage: (language) => set({ language }),
  t: (item) => {
    if (item.translate) {
      return item.value[get().language] || "";
    } else {
      return item.value["*"] || "";
    }
  },
}));
