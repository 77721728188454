import create from "zustand";

export const useUser = create((set) => ({
  user: null,
  slug: null,
  setUser: (user) => {
    set({ user });
  },
  setSlug: (slug) => {
    set({ slug });
  },
}));
