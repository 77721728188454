import React from "react";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import Loading from "react-loading";
import "./StyledButton.scss";

export default function StyledButton({
  children,
  onClick,
  status = "start",
  color,
  style,
}) {
  return (
    <div className={"StyledButton " + color} onClick={onClick} style={style}>
      {status === "loading" ? (
        <Loading type="spin" height={15} width={15}></Loading>
      ) : status === "error" ? (
        <FaExclamationTriangle></FaExclamationTriangle>
      ) : status === "done" ? (
        <FaCheck></FaCheck>
      ) : (
        children
      )}
    </div>
  );
}
