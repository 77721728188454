import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { db } from "../firebase";

export default function useDocument(path) {
  let [data, setData] = useState({});
  let [status, setStatus] = useState("start");
  let [updateStatus, setUpdateStatus] = useState("start");
  let [deleteStatus, setDeleteStatus] = useState("start");
  const docRef = useMemo(() => doc(db, path), [path]);

  const reload = useCallback(() => {
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setStatus("done");
        setData(docSnap.data());
      } else {
        setStatus("error");
        // doc.data() will be undefined in this case
      }
    });
  }, [setStatus, setData, docRef]);

  useEffect(() => {
    setStatus("loading");
    reload();
  }, [setStatus, reload]);

  const updateDocument = useCallback(
    (d) => {
      setUpdateStatus("loading");
      return updateDoc(docRef, d)
        .then(() => {
          setUpdateStatus("done");
        })
        .catch(() => {
          setUpdateStatus("error");
        });
    },
    [docRef]
  );
  const deleteDocument = useCallback(
    (d) => {
      setDeleteStatus("loading");
      return deleteDoc(docRef, d)
        .then(() => {
          setDeleteStatus("done");
        })
        .catch(() => {
          setDeleteStatus("error");
        });
    },
    [docRef]
  );
  return {
    data,
    updateDocument,
    status,
    updateStatus,
    deleteDocument,
    deleteStatus,
    reload,
  };
}
