import React, { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FBLoader from "../../atoms/FBLoader/FBLoader";
import SlimMap from "../../atoms/SlimMap/SlimMap";
import Layout from "../../atoms/Layout/Layout";
import useQuery from "../../hooks/useQuery";
import { useUser } from "../../hooks/useUser";
import "./EditTour.scss";
import { DumnDot } from "../../atoms/FullMap/FullMap";
import { CatmullRomLine } from "@react-three/drei";
import PointSearchList from "../../atoms/PointSearchList/PointSearchList";
import DNDList from "../../atoms/DNDList/DNDList";
import { FaQrcode, FaTrash } from "react-icons/fa";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import LanguageSwitch from "../../atoms/LanguageSwitch/LanguageSwitch";
import useTranslateDocument from "../../hooks/useTranslateDocument";
import Spacer from "../../atoms/Spacer/Spacer";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ShortLinkMaker from "../../atoms/ShortLinkMaker/ShortLinkMaker";
import Modal from "../../atoms/Modal/Modal";
import Label from "../../atoms/Label/Label";

export default function EditTour() {
  let { tourId } = useParams();
  let { user } = useUser();
  let { mappedResults } = useQuery("exhibits", "user", "==", user.uid);
  const nav = useNavigate();
  let { data: client } = useTranslateDocument("clients/" + user.uid);
  let {
    data: tourData,
    updateDocument,
    status,
    updateStatus,
    setField,
    deleteDocument,
    deleteStatus,
  } = useTranslateDocument("/tours/" + tourId);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <Layout className="EditTour" title="Führung bearbeiten">
      {modalOpen && (
        <Modal
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <ShortLinkMaker type="tour" targetId={tourData.id}></ShortLinkMaker>
        </Modal>
      )}
      {tourData.id && (
        <>
          <div className="title">Tour: {tourData.name}</div>
          <Spacer h={12}></Spacer>
          <LanguageSwitch>
            <div className="canvasWrapper">
              {mappedResults &&
                mappedResults.length > 0 &&
                client &&
                client.floorplan && (
                  <SlimMap floorplan={client.floorplan}>
                    {mappedResults
                      .filter((r) => {
                        return r.point;
                      })
                      .map((r) => {
                        return (
                          <DumnDot
                            key={r.id}
                            onClick={() => {
                              if (!r.isPOI && !tourData.points.includes(r.id)) {
                                setField(
                                  "points",
                                  [...tourData.points, r.id],
                                  true
                                );
                              }
                            }}
                            data={r}
                          ></DumnDot>
                        );
                      })}

                    {tourData.points.filter((id) =>
                      mappedResults.find((p) => p.id === id && p.point)
                    ).length > 1 && (
                      <Line
                        points={tourData.points
                          .filter((id) =>
                            mappedResults.find((p) => p.id === id && p.point)
                          )
                          .map((id) => {
                            let point = mappedResults.find((p) => p.id === id);

                            return (point && point.point) || null;
                          })

                          .map((o) => {
                            return [o.x, o.y, o.z];
                          })}
                      ></Line>
                    )}
                  </SlimMap>
                )}
              <div className="editTourPointsOverlay box shadow">
                <FBLoader status={status}>
                  {tourData && tourData.points && (
                    <>
                      <Spacer h={12}></Spacer>
                      <Label>Punkte der Tour anordnen</Label>
                      <Spacer h={12}></Spacer>
                      <DNDList
                        hasTexts={tourData.isTreasureHunt}
                        pointTexts={tourData.pointTexts}
                        pointImages={tourData.pointImages}
                        setPointImages={(p, t) => {
                          setField("pointImages", {
                            ...tourData.pointImages,
                            [p]: t,
                          });
                        }}
                        setPointText={(p, t) => {
                          setField("pointTexts", {
                            ...tourData.pointTexts,
                            [p]: t,
                          });
                          /* setLocalTour({
                            ...localTour,
                            pointTexts: { ...localTour.pointTexts, [p]: t },
                          });*/
                        }}
                        value={tourData.points
                          .filter((id) =>
                            mappedResults.find((p) => p.id === id && p.point)
                          )
                          .map((id) => {
                            let point = mappedResults.find((p) => p.id === id);
                            return point;
                          })}
                        onChange={(l) => {
                          setField(
                            "points",
                            l.map((i) => i.id),
                            true
                          );
                        }}
                      ></DNDList>

                      <Spacer h={12}></Spacer>
                      <PointSearchList
                        points={mappedResults.filter(
                          (v) => !tourData.points.includes(v.id) && v.point
                        )}
                        onPointClicked={(v) => {
                          //add to tour.
                          if (!tourData.points.includes(v.id)) {
                            setField(
                              "points",
                              [...tourData.points, v.id],
                              true
                            );
                          }
                        }}
                      ></PointSearchList>
                    </>
                  )}
                </FBLoader>
                <Spacer h={12}></Spacer>

                <StyledButton
                  status={updateStatus}
                  onClick={() => {
                    updateDocument({
                      ...tourData,
                    });
                  }}
                >
                  Änderungen speichern
                </StyledButton>
                <Spacer h={8}></Spacer>
                <Link to={"/editTour/" + tourData.id}>
                  <StyledButton color="grey">Tour bearbeiten</StyledButton>
                </Link>
                <Spacer h={8}></Spacer>
                <StyledButton
                  color={"grey"}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  <FaQrcode></FaQrcode>
                  <Spacer w={8}></Spacer>
                  QR Code Einstellungen
                </StyledButton>
                <Spacer h={8}></Spacer>
                <StyledButton
                  status={deleteStatus}
                  color={"red"}
                  onClick={() =>
                    confirmAlert({
                      title: "Sind Sie sicher?",
                      message: "Exponat unwiderruflich löschen",
                      buttons: [
                        {
                          label: "Ja",
                          onClick: () =>
                            deleteDocument().then(() => {
                              nav("/tours");
                            }),
                        },
                        {
                          label: "Nein",
                          onClick: () => {},
                        },
                      ],
                    })
                  }
                >
                  <FaTrash></FaTrash>
                  <Spacer w={8}></Spacer>
                  Tour löschen
                </StyledButton>
              </div>
            </div>
          </LanguageSwitch>
        </>
      )}
    </Layout>
  );
}

function Line({ points }) {
  let ref = useRef();

  return (
    <CatmullRomLine
      ref={ref}
      points={points} // Array of Points
      closed={false} // Default
      curveType="centripetal" // One of "centripetal" (default), "chordal", or "catmullrom"
      color="green" // Default
      lineWidth={5}
      segments={100}
      dashed={false} // Default
    ></CatmullRomLine>
  );
}
