import React from "react";
import "./Modal.scss";
import { FaTimes } from "react-icons/fa";
export default function Modal({ onClose, children }) {
  return (
    <div className="Modal" onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()} className="modalInner">
        {children}
        <div className="closeModalButton" onClick={onClose}>
          <FaTimes></FaTimes>
        </div>
      </div>
    </div>
  );
}
