import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLanguage } from "../../src/hooks/useLanguage";
import { db } from "../firebase";
import { ProjectTranslateableDocument } from "./useCreateTranslateableDocument";

export default function useTranslateDocument(path, autoFetch = true) {
  let { language } = useLanguage();
  let [data, setData] = useState({});
  let [status, setStatus] = useState("start");
  let [updateStatus, setUpdateStatus] = useState("start");
  let [deleteStatus, setDeleteStatus] = useState("start");
  const docRef = useMemo(() => doc(db, path), [path]);

  const reload = useCallback(() => {
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setStatus("done");
        setData(docSnap.data());
      } else {
        setStatus("error");
        // doc.data() will be undefined in this case
      }
    });
  }, [setStatus, setData, docRef]);
  const tid = useRef(null);
  const updateDocument = useCallback(() => {
    setUpdateStatus("loading");

    return updateDoc(docRef, data).then(() => {
      setUpdateStatus("done");
      if (tid.current) clearTimeout(tid.current);
      tid.current = setTimeout(() => {
        setUpdateStatus("start");
      }, 3000);
    });
  }, [data, docRef]);

  const deleteDocument = useCallback(() => {
    setDeleteStatus("loading");

    return deleteDoc(docRef).then(() => {
      setDeleteStatus("done");
    });
  }, [docRef]);

  const setField = useCallback(
    (key, value, writeImmediate) => {
      let res = {};
      //set field for current language
      if (!data[key]) {
        res = { [key]: { t: true, value: { [language]: value } } };
      } else if (data[key].t === true) {
        res = {
          [key]: {
            ...data[key],
            value: { ...data[key].value, [language]: value },
          },
        };
      } else if (data[key].t === false) {
        res = {
          [key]: {
            ...data[key],
            value: { ...data[key].value, "*": value },
          },
        };
      } else {
        res = {
          [key]: value,
        };
      }

      setData((state) => ({ ...state, ...res }));
      if (writeImmediate) {
        updateDoc(docRef, res).then(() => {
          setUpdateStatus("done");
        });
      }
    },
    [language, data, setUpdateStatus, docRef]
  );

  useEffect(() => {
    if (autoFetch) {
      setStatus("loading");
      reload();
    }
  }, [setStatus, reload, autoFetch]);

  let mappedRes = useMemo(() => {
    return ProjectTranslateableDocument(data, language);
  }, [data, language]);

  return {
    data: mappedRes,
    setField,
    status,
    reload,
    updateDocument,
    deleteDocument,
    updateStatus,
    deleteStatus,
  };
}
