import React from "react";
import ClientForm from "../../atoms/ClientForm/ClientForm";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import useTranslateDocument from "../../hooks/useTranslateDocument";
import { useUser } from "../../hooks/useUser";
import "./EditClient.scss";

export default function EditClient() {
  let { user } = useUser();
  let { data, updateDocument, updateStatus, setField } = useTranslateDocument(
    "clients/" + user.uid
  );

  return (
    <Layout className="EditClient" title="Ihre Grundeinstellungen">
      <Spacer h={12}></Spacer>

      {data && data.id && (
        <ClientForm
          value={data}
          setField={setField}
          status={updateStatus}
          onComplete={(data) => {
            updateDocument(data).then(() => {});
          }}
        ></ClientForm>
      )}
    </Layout>
  );
}
