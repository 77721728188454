import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import FBLoader from "../../atoms/FBLoader/FBLoader";
import LanguageSwitch from "../../atoms/LanguageSwitch/LanguageSwitch";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import TextButton from "../../atoms/TextButton/TextButton";
import useQuery from "../../hooks/useQuery";
import { useUser } from "../../hooks/useUser";
import "./TourList.scss";
import { db } from "../../firebase";
import { doc, deleteDoc } from "firebase/firestore";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import StyledInput from "../../atoms/StyledInput/StyledInput";

export default function TourList() {
  let { user } = useUser();

  let { mappedResults, status, reload } = useQuery(
    "tours",
    "user",
    "==",
    user.uid
  );
  let [search, setSearch] = useState("");

  return (
    <Layout className="TourList" title="Ihre Führungen">
      <Spacer h={12}></Spacer>
      <LanguageSwitch>
        <StyledInput
          placeholder="Suchen"
          value={search}
          onChange={setSearch}
        ></StyledInput>

        <Spacer h={12}></Spacer>
        <FBLoader status={status}>
          {mappedResults
            .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => {
              if (a.isTreasureHunt && !b.isTreasureHunt) {
                return 1;
              }
              if (!a.isTreasureHunt && b.isTreasureHunt) {
                return -1;
              }
              return 0;
            })
            .map((tour) => {
              return (
                <Link
                  className={
                    "tourResult " +
                    (tour.isTreasureHunt ? "treasureHunt" : "noTreasure")
                  }
                  key={tour.id}
                  to={"/tour/" + tour.id}
                >
                  <div className="img">
                    <img
                      alt="Touricon"
                      src={tour.image || "https://via.placeholder.com/60"}
                    ></img>
                  </div>
                  <div className="tourName">{tour.name}</div>
                  <div
                    className="deleteTourButton"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      confirmAlert({
                        title: "Sind Sie sicher?",
                        message: "Exponat unwiderruflich löschen",
                        buttons: [
                          {
                            label: "Ja",
                            onClick: () =>
                              deleteDoc(doc(db, "/tours/" + tour.id)).then(
                                () => {
                                  reload();
                                }
                              ),
                          },
                          {
                            label: "Nein",
                            onClick: () => {},
                          },
                        ],
                      });
                    }}
                  >
                    <FaTrash></FaTrash>
                  </div>
                </Link>
              );
            })}
        </FBLoader>
      </LanguageSwitch>
      <Spacer h={12}></Spacer>
      <Link to={"/createTour"}>
        <TextButton icon={<FaPlus></FaPlus>}>Neue Führung anlegen</TextButton>
      </Link>
    </Layout>
  );
}
