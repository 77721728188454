import React from "react";
import {
  FaCog,
  FaCompass,
  FaLock,
  FaMap,
  FaQrcode,
  FaTrophy,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import "./Home.scss";
//import { useUser } from "../../hooks/useUser";
//import useDocument from "../../hooks/useDocument";

export default function Home() {
  return (
    <Layout className="Home">
      <div className="homeBoxes">
        <HomeBox
          link={"/collection"}
          icon={<FaTrophy></FaTrophy>}
          name="Exponat Sammlung"
          text={"Verwalten Sie\nIhre Ausstellungsstücke"}
        ></HomeBox>
        <HomeBox
          link={"/place"}
          icon={<FaMap />}
          name="Karte"
          text={"Grundriss Ihrer\nEinrichtung verwalten"}
        ></HomeBox>
        <HomeBox
          link={"/tours"}
          icon={<FaCompass />}
          name="Führungen"
          text={"Geführte Touren\ndurch die Ausstellung"}
        ></HomeBox>
        <HomeBox
          link={"/qr"}
          icon={<FaQrcode />}
          name="QR Codes"
          text={"Verwalten Sie\nIhre QR Codes"}
        ></HomeBox>
        <HomeBox
          link={"/unlockCodes"}
          icon={<FaLock />}
          name="Freischaltcodes"
          text={"Generieren Sie Freischaltcodes\nfür Ihre Besucher"}
        ></HomeBox>
        <HomeBox
          link={"/editClient"}
          icon={<FaCog />}
          name="Grundeinstellungen"
          text={"Einstellungen für\nIhre Einrichtung"}
        ></HomeBox>
      </div>
    </Layout>
  );
}

function HomeBox({ icon = "", name = "", text = "", bottom = "", link = "" }) {
  return (
    <div className="homeBox shadow">
      <div className="boxTop">
        <div className="boxIcon">{icon}</div>
        <div className="boxText">
          <div className="name">{name}</div>
          <div className="desc">{text}</div>
        </div>
      </div>
      <div className="boxBottom">{bottom}</div>
      <div className="boxButton">
        <Link to={link}>
          <StyledButton>Verwalten</StyledButton>
        </Link>
      </div>
    </div>
  );
}
