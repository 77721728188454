import React, { useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./DNDList.scss";
import { MdDragIndicator } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import Spacer from "../Spacer/Spacer";
import StyledInput from "../StyledInput/StyledInput";
import ImageUploader from "../ImageUploader/ImageUploader";
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,
  borderRadius: 8,
  paddingLeft: 4,
  paddingRight: 4,

  // change background colour if dragging
  background: isDragging ? "rgb(33, 150, 243)" : "#ddd",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const grid = 8;

export default function DNDList({
  value,
  onChange,
  pointTexts,
  setPointText,
  pointImages = {},
  setPointImages,
  hasTexts,
}) {
  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const _items = reorder(
        value,
        result.source.index,
        result.destination.index
      );

      onChange(_items);
    },
    [value, onChange]
  );

  return (
    <div className="DNDList">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              className="dndInnerList"
              ref={provided.innerRef}
            >
              {value.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  id={item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className="dndItem"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div className="num">{index + 1}.</div>
                      <img
                        alt="thumb"
                        className="dndThumb"
                        src={
                          item.thumbnail ||
                          (item.images && item.images[0]) ||
                          "https://via.placeholder.com/60"
                        }
                      ></img>
                      <div className="dndItemName">
                        <div>
                          {hasTexts && (
                            <>
                              <StyledInput
                                label={"Suchtext"}
                                placeholder={"Suche nach dem ..."}
                                onChange={(e) => setPointText(item.id, e)}
                                value={pointTexts[item.id] || ""}
                              ></StyledInput>
                              {pointImages[item.id] ? (
                                <div>
                                  <img
                                    alt="such bild"
                                    src={pointImages[item.id]}
                                    width={200}
                                  ></img>
                                  <div
                                    title="Suchbild löschen"
                                    className="deleteImage"
                                    style={{
                                      fontSize: "80%",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      color: "darkred",
                                    }}
                                    onClick={() => {
                                      setPointImages(item.id, null);
                                    }}
                                  >
                                    Suchbild löschen
                                  </div>
                                </div>
                              ) : (
                                <ImageUploader
                                  value={pointImages[item.id]}
                                  onChange={(e) => setPointImages(item.id, e)}
                                ></ImageUploader>
                              )}
                            </>
                          )}
                        </div>
                        <Spacer h={24}></Spacer>
                        {item.name}
                      </div>
                      <div
                        className="dndIcon"
                        onClick={() => {
                          onChange(value.filter((v) => v.id !== item.id));
                        }}
                      >
                        <FaTrash></FaTrash>
                      </div>
                      <Spacer w={12}></Spacer>
                      <div className="dndIcon">
                        <MdDragIndicator></MdDragIndicator>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
