import React from "react";
import Layout from "../../atoms/Layout/Layout";
import QrManager from "../../atoms/QrManager/QrManager";
import "./QrEditScreen.scss";

export default function QrEditScreen() {
  return (
    <Layout className="QrEditScreen">
      <QrManager></QrManager>
    </Layout>
  );
}
