import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import FBLoader from "../../atoms/FBLoader/FBLoader";
import LanguageSwitch from "../../atoms/LanguageSwitch/LanguageSwitch";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";

import StyledInput from "../../atoms/StyledInput/StyledInput";
import TextButton from "../../atoms/TextButton/TextButton";
import useQuery from "../../hooks/useQuery";
import { useUser } from "../../hooks/useUser";
import "./Collection.scss";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { getFunctions, httpsCallable } from "firebase/functions";

export default function Collection() {
  const functions = getFunctions();
  const deleteExhibit = httpsCallable(functions, "deleteExhibit");

  let { user } = useUser();

  let {
    mappedResults: exhibits,
    reload,
    status: listStatus,
  } = useQuery("/exhibits", "user", "==", user.uid, "isPOI", "==", false);
  let [search, setSearch] = useState("");
  return (
    <Layout className="Collection" title="Ihre Sammlung" backURL={"/"}>
      <Spacer h={12}></Spacer>
      <LanguageSwitch>
        <Link to="/createExhibit">
          <TextButton icon={<FaPlus></FaPlus>}>
            Neues Exponat anlegen
          </TextButton>
        </Link>
        <Spacer h={12}></Spacer>
        <StyledInput
          placeholder="Suchen"
          value={search}
          onChange={setSearch}
        ></StyledInput>
        <Spacer h={12}></Spacer>
        <FBLoader status={listStatus}>
          {exhibits
            .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => a.id.localeCompare(b.id))
            .map((e) => {
              return (
                <Link
                  to={"/exhibit/" + e.id}
                  key={e.id}
                  className="exhibitRow shadow"
                >
                  <img
                    src={
                      (e.thumbnail && e.thumbnail) ||
                      (e.images && e.images[0]) ||
                      "https://via.placeholder.com/60"
                    }
                    alt="Exhibit"
                  />
                  <div className="exhibitName">{e.name}</div>
                  <div
                    className="deleteExhibitButton"
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();
                      confirmAlert({
                        title: "Sind Sie sicher?",
                        message: "Exponat unwiderruflich löschen",
                        buttons: [
                          {
                            label: "Ja",
                            onClick: () => {
                              deleteExhibit({ exhibitId: e.id }).then(() => {
                                reload();
                              });
                            },
                          },
                          {
                            label: "Nein",
                            onClick: () => {},
                          },
                        ],
                      });
                    }}
                  >
                    <FaTrash></FaTrash>
                  </div>
                </Link>
              );
            })}
        </FBLoader>
        <Spacer h={12}></Spacer>
      </LanguageSwitch>
    </Layout>
  );
}
