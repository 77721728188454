import React, { useCallback, useMemo, useState } from "react";
import "./ImageUploader.scss";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import app from "../../firebase";
import { useDropzone } from "react-dropzone";
import Loading from "react-loading";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: 2,
  borderRadius: 8,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  fontWeight: "bold",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 2px 0px #00000044",
  color: "#222",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function ImageUploader({
  value,
  onChange = () => {},
  multiple = false,
  label,
  accept = false,
  doneText,
}) {
  const storage = useMemo(() => getStorage(app), []);
  const [status, setStatus] = useState("start");
  const ul = useCallback(
    (files) => {
      console.log(files);
      setStatus("loading");
      let uploadPromises = files.map((f) => {
        const storageRef = ref(storage, uuidv4());
        return uploadBytes(storageRef, f);
      });
      Promise.all(uploadPromises).then((res) => {
        //for all res, get DL url
        let dlUrlPromises = res.map((r) => {
          console.log(r);
          return getDownloadURL(r.ref);
        });
        Promise.all(dlUrlPromises).then((dlUrlArray) => {
          if (!multiple) {
            onChange(dlUrlArray[0], files[0].name);
          } else {
            onChange([...value, ...dlUrlArray]);
          }
          setStatus("done");
        });
      });
    },
    [storage, onChange, value, multiple]
  );
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      if (acceptedFiles && acceptedFiles.length > 0) {
        ul(acceptedFiles);
      }
    },
    [ul]
  );
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept,
    multiple: multiple,
    maxFiles: 25,
    maxSize: 10000000,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : status === "loading" ? (
        <Loading type="spin" height={30} width={30} color="#222222"></Loading>
      ) : status === "start" ? (
        label || "Dateien hier ablegen"
      ) : status === "error" ? (
        "Something went wrong"
      ) : status === "done" ? (
        doneText || "Fertig"
      ) : (
        ""
      )}
    </div>
  );
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
