import React, { useEffect } from "react";
import Layout from "../../atoms/Layout/Layout";
import FullMap, { useMapData } from "../../atoms/FullMap/FullMap";
import "./PlaceExhibitScreen.scss";
import useQuery from "../../hooks/useQuery";
import { useUser } from "../../hooks/useUser";
import PointSearchList from "../../atoms/PointSearchList/PointSearchList";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import Spacer from "../../atoms/Spacer/Spacer";
import { FaPen, FaPlus, FaTimes, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { updateDocument } from "../../lib/fbHelpers";
import LanguageSwitch from "../../atoms/LanguageSwitch/LanguageSwitch";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import TextButton from "../../atoms/TextButton/TextButton";
import Label from "../../atoms/Label/Label";
import useCreateTranslateableDocument from "../../hooks/useCreateTranslateableDocument";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
export default function PlaceExhibitScreen() {
  let { user } = useUser();
  let { mappedResults, reload } = useQuery("exhibits", "user", "==", user.uid);
  let {
    setSelectedPoint,
    setPoints,
    points,
    selectedPoint,
    setOverlay,
    updatePoint,
    overlay,
  } = useMapData();
  useEffect(() => {
    setSelectedPoint(null);
    setPoints(mappedResults);
  }, [setPoints, mappedResults, setSelectedPoint]);
  useEffect(() => {
    setOverlay(null);
    
  }, [setOverlay]);

  let { create } = useCreateTranslateableDocument(
    {
      name: { t: true, default: "" },
      infos: { t: true, default: {} },
      size: { t: false, default: "" },
      description: { t: true, default: "" },
      video: { t: true, default: "" },
      audio: { t: true, default: "" },
      style: {
        t: false,
        default: { size: 2, color: "#ff0000", type: "dot", thumbnail: "" },
      },

      model: {
        t: false,
        default: {
          size: 1,
          file: "",
          angle: 1.2,
          rotateSpeed: 0.5,
          fov: 45,
          light: 1,
          environment: null,
          color: "#444444",
        },
      },
      images: { t: false, default: [] },
    },
    "/exhibits"
  );

  let newPoints = points.filter((p) => (!p.point || p.point === 0) && !p.isPOI);

  return (
    <Layout
      className="PlaceExhibitScreen"
      title="Exponate auf der Karte platzieren"
    >
      <Spacer h={12}></Spacer>
      <LanguageSwitch>
        <FullMap
          reload={reload}
          onClick={() => {
            
          }}
        >
          <div className="sidebar">
            {newPoints.length > 0 ? (
              <>
                <Label>Nicht platzierte Exponate:</Label>
                <PointSearchList
                  onPointClicked={(p) => {
                    setSelectedPoint(p);
                    updatePoint({ ...p, point: { x: 0, y: 0, z: 0 } });
                    // lang switch bug.
                    updateDocument("exhibits/" + p.id, {
                      point: {
                        x: 0,
                        y: 0,
                        z: 0.2,
                      },
                    }).then(() => {
                      reload().then(() => {
                        setOverlay(p.id);
                      });
                    });
                  }}
                  points={newPoints}
                ></PointSearchList>
                <Spacer h={12}></Spacer>
              </>
            ) : (
              <Label>Alle Exponate platziert</Label>
            )}
            <POISelector create={create} reload={reload}></POISelector>
            <div style={{ minWidth: 50 }}>
              <Link to="/createExhibit">
                <TextButton icon={<FaPlus></FaPlus>}>Neues Exponat</TextButton>
              </Link>
              {/*<StyledButton onClick={() => nav("/createExhibit")}>
                  <FaPlus />
              </StyledButton>*/}
            </div>
            {selectedPoint && (
              <>
                <Spacer h={8}></Spacer>

                <StyledButton
                  color="grey"
                  onClick={() => setSelectedPoint(null)}
                >
                  Abbrechen
                </StyledButton>
              </>
            )}
          </div>

          {overlay && <EditPointOverlay reload={reload}></EditPointOverlay>}
        </FullMap>
      </LanguageSwitch>
    </Layout>
  );
}

function EditPointOverlay({ reload }) {
  let { points, updatePoint, setOverlay, overlay } = useMapData();
  let nav = useNavigate();

  let thePoint = points.find((p) => p.id === overlay);
  const isPOI = thePoint.isPOI;
  return (
    <div className="styleOverlay">
      <div className="closeOverlay" onClick={() => setOverlay(null)}>
        <FaTimes></FaTimes>
      </div>
      {!isPOI && (
        <>
          Punkt: <b>{thePoint.name}</b>
        </>
      )}
      <Spacer h={12}></Spacer>
      Größe anpassen:
      <Spacer h={12}></Spacer>
      <div className="sliderHolder">
        <Slider
          value={thePoint.style.size}
          onChange={(e) => {
            updatePoint({
              id: thePoint.id,
              style: {
                ...thePoint.style,
                size: e,
              },
            });
          }}
          min={1}
          max={8}
          step={0.1}
          onAfterChange={(e) => {
            updateDocument("exhibits/" + thePoint.id, {
              style: {
                t: false,
                value: {
                  "*": { ...thePoint.style, size: e },
                },
              },
            });
          }}
        ></Slider>
        <input
          className="sizeInput"
          type="number"
          onChange={(e) => {
            updatePoint({
              id: thePoint.id,
              style: {
                ...thePoint.style,
                size: e.target.value,
              },
            });
            updateDocument("exhibits/" + thePoint.id, {
              style: {
                t: false,
                value: {
                  "*": { ...thePoint.style, size: e.target.value},
                },
              },
            });
          }}
          value={thePoint.style.size}
        ></input>
      </div>
      {!isPOI && (
        <>
          <Spacer h={12}></Spacer>
          <div
            className="iconAction"
            onClick={() => {
              nav("/exhibit/" + thePoint.id);
            }}
          >
            <div className="icon">
              <FaPen></FaPen>
            </div>
            <div className="actionName">Bearbeiten</div>
          </div>

          <Spacer h={8}></Spacer>
          <div
            className="iconAction"
            onClick={() => {
              updateDocument("exhibits/" + thePoint.id, {
                point: 0,
              })
                .then(reload)
                .then(() => {
                  setOverlay(null);
                });
            }}
          >
            <div className="icon">
              <FaTrash></FaTrash>
            </div>
            <div className="actionName">Zurücklegen</div>
          </div>
        </>
      )}
      {isPOI && (
        <>
          <Spacer h={12}></Spacer>
          <div
            className="iconAction"
            onClick={() => {
              deleteDoc(doc(db, "exhibits/" + thePoint.id))
                .then(reload)
                .then(() => {
                  setOverlay(null);
                });
            }}
          >
            <div className="icon">
              <FaTrash></FaTrash>
            </div>
            <div className="actionName">Löschen</div>
          </div>
        </>
      )}
    </div>
  );
}

function POISelector({ create, reload }) {
  const POIs = [
    {
      name: "Tür",
      icon: "/POI/door.png",
      type: "door",
    },
    {
      name: "Info",
      icon: "/POI/info.png",
      type: "info",
    },
    {
      name: "WC",
      icon: "/POI/wc.png",
      type: "wc",
    },
    {
      name: "Treppe",
      icon: "/POI/stairs.png",
      type: "stairs",
    },
    {
      name: "Aufzug",
      icon: "/POI/elevator.png",
      type: "elevator",
    }, {
      name: "Bistro",
      icon: "/POI/bistro.png",
      type: "bistro",
    },
    {
      name: "Shop",
      icon: "/POI/shop.png",
      type: "shop",
    },
  ];
  return (
    <div className="poiSelector">
      {POIs.map((poi) => {
        return (
          <div
            className="poiItem"
            onClick={() => {
              create({
                isPOI: true,
                poiType: poi.type,
                point: { x: 0, y: 0, z: 0 },
                style: {
                  t: false,
                  value: {
                    "*": {
                      size: 5,
                      color: "#ff0000",
                      type: "image",
                      thumbnail: poi.icon,
                    },
                  },
                },
              }).then(() => reload());
            }}
          >
            <img alt={poi.name} src={poi.icon}></img>
            <div className="poiName">{poi.name}</div>
          </div>
        );
      })}
    </div>
  );
}
