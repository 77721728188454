import { collection, query, where, getDocs } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { db } from "../firebase";
import { ProjectTranslateableDocument } from "./useCreateTranslateableDocument";
import { useLanguage } from "./useLanguage";

export default function useQuery(
  coll,
  filter1,
  filter2,
  filter3,
  filter4,
  filter5,
  filter6
) {
  let [status, setStatus] = useState("start");
  let { language } = useLanguage();
  const [results, setResults] = useState([]);
  const q = useMemo(() => {
    if (filter4) {
      return query(
        collection(db, coll),
        where(filter1, filter2, filter3),
        where(filter4, filter5, filter6)
      );
    } else {
      return query(collection(db, coll), where(filter1, filter2, filter3));
    }
  }, [coll, filter1, filter2, filter3, filter4, filter5, filter6]);

  let reload = useCallback(() => {
    setStatus("loading");
    return getDocs(q).then((querySnapshot) => {
      let arr = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        arr.push(doc.data());
      });
      setResults(arr);
      setStatus("done");
    });
  }, [setResults, q]);
  useEffect(() => {
    reload();
  }, [setResults, q, reload]);
  const mappedResults = useMemo(() => {
    
    return results.map((r) =>
      ProjectTranslateableDocument(r, language || "DE")
    );
  }, [results, language]);
  return {
    results,
    mappedResults,
    status,
    reload,
  };
}
