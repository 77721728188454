import React, { useEffect, useRef } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import Label from "../Label/Label";
import Spacer from "../Spacer/Spacer";
import StyledInput from "../StyledInput/StyledInput";
import TextButton from "../TextButton/TextButton";
import "./KeyValueForm.scss";

export default function KeyValueForm({ value, onChange, label }) {
  return (
    <div className="KeyValueForm">
      <Label>{label}</Label>
      {Object.keys(value).map((k) => {
        let name = value[k].key;
        let val = value[k].value;
        return (
          <KVRow
            onChange={onChange}
            name={name}
            val={val}
            k={k}
            value={value}
          ></KVRow>
        );
      })}
      <Spacer h={12}></Spacer>
      <TextButton
        onClick={() => {
          onChange({ ...value, [Math.random()]: { key: "", value: "" } });
        }}
        icon={<FaPlus></FaPlus>}
      >
        Datenpunkt hinzufügen
      </TextButton>
    </div>
  );
}

function KVRow({ val, name, onChange, k, value }) {
  let ref = useRef();
  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);
  return (
    <div className="kvrow">
      <div className="key">
        <StyledInput
          ref={ref}
          placeholder="Titel"
          type="text"
          value={name}
          onChange={(t) => {
            onChange({ ...value, [k]: { ...value[k], key: t } });
          }}
        ></StyledInput>
      </div>
      <div className="value">
        <StyledInput
          placeholder="Wert"
          type="text"
          value={val}
          onChange={(t) => {
            onChange({ ...value, [k]: { ...value[k], value: t } });
          }}
        ></StyledInput>
      </div>
      <div
        className="removeRowButton"
        onClick={() => {
          let { [k]: test, ...rest } = value;
          onChange(rest);
        }}
      >
        <FaTrash></FaTrash>
      </div>
    </div>
  );
}
