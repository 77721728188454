import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home/Home";
import Nav from "./atoms/Nav/Nav";
import Login from "./screens/Login/Login";
import app from "./firebase";
import { getAuth } from "firebase/auth";

import { useUser } from "./hooks/useUser";
import { useEffect } from "react";

import Loading from "react-loading";
import useDocument from "./hooks/useDocument";
import EditClient from "./screens/EditClient/EditClient";
import Collection from "./screens/Collection/Collection";
import EditExhibit from "./screens/EditExhibit/EditExhibit";
import CreateTour from "./screens/CreateTour/CreateTour";
import TourList from "./screens/TourList/TourList";
import EditTour from "./screens/EditTour/EditTour";

import PlaceExhibitScreen from "./screens/PlaceExhibitScreen/PlaceExhibitScreen";
import CreateExhibit from "./screens/CreateExhibit/CreateExhibit";

import CreateClient from "./screens/CreateClient/CreateClient";
import EditTourScreen from "./screens/EditTourScreen/EditTourScreen";
import QrEditScreen from "./screens/QrEditScreen/QrEditScreen";
import UnlockCodes from "./screens/UnlockCodes/UnlockCodes";
function App() {
  const auth = getAuth(app);

  let setUser = useUser((s) => s.setUser);
  let user = useUser((s) => s.user);
  auth.onAuthStateChanged((user) => {
    if (user) {
      // User is signed in.
      setUser(user);
    }
  });

  return (
    <BrowserRouter>
      <Nav></Nav>
      {user ? (
        <LoadClientSplash uid={user.uid}>
          <Routes>
            <Route path="/" element={<Home></Home>}></Route>
            <Route path="/login" element={<Login></Login>}></Route>
            <Route path="/qr" element={<QrEditScreen></QrEditScreen>}></Route>
            <Route path="/tours" element={<TourList></TourList>}></Route>
            <Route path="/tour/:tourId" element={<EditTour></EditTour>}></Route>
            <Route
              path="/editTour/:tourId"
              element={<EditTourScreen />}
            ></Route>
            <Route
              path="/createTour"
              element={<CreateTour></CreateTour>}
            ></Route>
            <Route path="/place" element={<PlaceExhibitScreen />}></Route>
            <Route
              path="/collection"
              element={<Collection></Collection>}
            ></Route>
            <Route
              path="/createExhibit"
              element={<CreateExhibit></CreateExhibit>}
            ></Route>{" "}
            <Route
              path="/unlockCodes"
              element={<UnlockCodes></UnlockCodes>}
            ></Route>
            <Route
              path="/exhibit/:id"
              element={<EditExhibit></EditExhibit>}
            ></Route>
            <Route path="/editClient" element={<EditClient />}></Route>
            <Route path="/createClient" element={<CreateClient />}></Route>
          </Routes>
        </LoadClientSplash>
      ) : (
        <Routes>
          <Route path="/login" element={<Login></Login>}></Route>
          <Route path="/" element={<Login></Login>}></Route>
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;

function LoadClientSplash({ uid, children }) {
  let { data } = useDocument("clients/" + uid);

  let setSlug = useUser((s) => s.setSlug);
  useEffect(() => {
    if (data && data.slug) {
      setSlug(data.slug);
    }
  }, [data, setSlug]);
  if (!data) {
    return <LoadingScreen></LoadingScreen>;
  }
  return children;
}

function LoadingScreen() {
  return (
    <div>
      <Loading type="spin"></Loading>
    </div>
  );
}
