import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import TourForm from "../../atoms/TourForm/TourForm";
import useCreateTranslateableDocument from "../../hooks/useCreateTranslateableDocument";
import "./CreateTour.scss";
import {
  query,
  orderBy,
  limit,
  doc,
  getDocs,
  collection,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useUser } from "../../hooks/useUser";

export default function CreateTour() {
  let { status, create, data, setField } = useCreateTranslateableDocument(
    {
      name: { t: true, default: "" },
      duration: { t: true, default: "" },
      points: { t: false, default: [] },
      text: { t: true, default: "" },
      age: { t: false, default: "" },
      image: { t: false, default: null },
      pointTexts: { t: true, default: [] },
      pointImages: { t: false, default: [] },
      isTreasureHunt: { t: false, default: false },
      isEnabled: { t: false, default: false },
    },
    "tours"
  );
  let nav = useNavigate();

  const { user } = useUser();
  return (
    <Layout className="CreateTour" title="Neue Führung erstellen">
      <Spacer h={12}></Spacer>
      <TourForm
        onComplete={(v) => {
          create().then((id) => {
            const q = query(
              collection(db, "shortCodes/"),
              where("user", "==", user.uid),
              orderBy("shortId", "desc"),
              limit(1)
            );
            getDocs(q).then((querySnapshot) => {
              let nextId = 1;
              if (querySnapshot.size > 0) {
                let lastId = parseInt(querySnapshot.docs[0].data().shortId);
                nextId = lastId + 1;
              }
              nextId = nextId.toString().padStart(3, "0");
              const docRef = doc(collection(db, "shortCodes/"));
              setDoc(docRef, {
                id: docRef.id,
                shortId: nextId,
                targetId: id,
                type: "tour",
                user: user.uid,
              }).then(() => {
                nav("/tour/" + id);
              });
            });
          });
        }}
        status={status}
        value={data}
        setField={setField}
      ></TourForm>
    </Layout>
  );
}
