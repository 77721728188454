import { doc, setDoc, collection } from "firebase/firestore";
import { useCallback, useState } from "react";
import { db } from "../firebase";
import { useLanguage } from "./useLanguage";
import { useUser } from "./useUser";

export default function useCreateTranslateableDocument(config, coll) {
  let { language } = useLanguage();
  let { user } = useUser();
  let [fields, setFields] = useState(makeTranslateableDocument(config));

  let [status, setStatus] = useState("start");

  const create = useCallback(
    (extra, id) => {
      let docRef = doc(collection(db, coll));
      if (id) {
        docRef = doc(collection(db, coll), id);
      }
      setStatus("loading");
      return setDoc(docRef, {
        ...fields,
        ...extra,
        user: user.uid,
        id: docRef.id,
      })
        .then(() => {
          setStatus("done");
          return docRef.id;
        })
        .catch((e) => {
          
          setStatus("error");
        });
    },
    [coll, fields, user.uid]
  );

  const setField = useCallback(
    (key, value) => {
      let res = {};
      //set field for current language
      if (fields[key].t === true) {
        res = {
          [key]: {
            ...fields[key],
            value: { ...fields[key].value, [language]: value },
          },
        };
      } else if (fields[key].t === false) {
        res = {
          [key]: {
            ...fields[key],
            value: { ...fields[key].value, "*": value },
          },
        };
      } else {
        res = {
          [key]: value,
        };
      }

      setFields((state) => ({ ...state, ...res }));
    },
    [language, fields]
  );
  return {
    status,
    create,
    setField,
    data: ProjectTranslateableDocument(fields, language),
  };
}

export function ProjectTranslateableDocument(doc, lang) {
  let res = {};
  Object.keys(doc).forEach((k) => {
    let field = doc[k];
    if (field.t === true) {
      //is translateable
      res[k] = field.value[lang] || field.default;
    } else if (field.t === false) {
      res[k] = field.value["*"];
    } else {
      res[k] = field;
    }
  });
  return res;
}

export function makeTranslateableDocument(config) {
  let language = "DE";
  let doc = {};
  Object.keys(config).forEach((k) => {
    let isTranslateable = config[k].t;
    if (isTranslateable) {
      doc[k] = { t: true, default: config[k].default };
      doc[k].value = { [language]: config[k].default };
    } else {
      doc[k] = { t: false, value: { "*": config[k].default } };
    }
  });
  return doc;
}
