import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import "./Layout.scss";
import app from "../../firebase";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import Spacer from "../Spacer/Spacer";
import { FaArrowLeft } from "react-icons/fa";

export default function Layout({
  children,
  className,
  style = {},
  title,
  backURL,
}) {
  const auth = getAuth(app);
  let user = useUser((s) => s.user);
  let setUser = useUser((s) => s.setUser);
  let nav = useNavigate();
  return (
    <>
      <div className="header">
        <Link to="/" className="logo">
          Avemus Admin
        </Link>
        <div className="flex1"></div>
        <div className="nav">
          {user && user.uid && (
            <Link to="/" className="navItem">
              Home
            </Link>
          )}
          <div
            onClick={() => {
              //nav("/");
              setUser(null);
              signOut(auth).then(() => {
                nav("/login");
              });
            }}
            className="navItem"
          >
            Logout
          </div>
        </div>
      </div>
      <div className={"inner " + className} style={style}>
        {title && (
          <>
            <div className="layoutTitle">
              <div
                className="backButton"
                onClick={() => (backURL ? nav(backURL) : nav(-1))}
              >
                <FaArrowLeft></FaArrowLeft>
              </div>
              {title}
            </div>
            <Spacer h={12}></Spacer>
          </>
        )}
        {children}
      </div>
    </>
  );
}
