import React from "react";
import ClientForm from "../../atoms/ClientForm/ClientForm";
import Layout from "../../atoms/Layout/Layout";
import useCreateTranslateableDocument from "../../hooks/useCreateTranslateableDocument";
import { useUser } from "../../hooks/useUser";
import "./CreateClient.scss";

export default function CreateClient() {
  let { user } = useUser();
  let { data, create, status, setField } = useCreateTranslateableDocument(
    {
      name: { t: true, default: "" },
      description: { t: true, default: "" },
      logo: { t: false, default: "" },
      image: { t: false, default: "" },
      floorplan: { t: false, default: "" },
    },
    "clients"
  );

  return (
    <Layout className="CreateClient">
      <ClientForm
        value={data}
        setField={setField}
        status={status}
        onComplete={() => {
          create({}, user.uid).then(() => {});
        }}
      ></ClientForm>
    </Layout>
  );
}
