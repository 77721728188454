import React, { useEffect, useRef } from "react";
import useQuery from "../../hooks/useQuery";
import StyledButton from "../StyledButton/StyledButton";
import StyledInput from "../StyledInput/StyledInput";
import "./ShortLinkMaker.scss";

import { saveAs } from "file-saver";
import {
  query,
  doc,
  getDocs,
  where,
  collection,
  updateDoc,
  setDoc,
} from "firebase/firestore";

import { db } from "../../firebase";
import { useUser } from "../../hooks/useUser";
import useTranslateDocument from "../../hooks/useTranslateDocument";
import { QRCode } from "react-qrcode-logo";
import Spacer from "../Spacer/Spacer";
const factor = 1;
const cornerStyles = {
  rund: [10 * factor, 10 * factor, 10 * factor, 10 * factor],
  eckig: [0, 0, 0, 0],
  tropfen: [
    [10 * factor, 10 * factor, 0, 10 * factor],
    [10 * factor, 10 * factor, 10 * factor, 0],
    [10 * factor, 0, 10 * factor, 10 * factor],
  ],
};
export default function ShortLinkMaker({
  targetId,
  value = "",
  onChange,
  type = "exhibit",
}) {
  const [id, setId] = React.useState(value);
  const { user } = useUser();

  const qrCodeRef = useRef();
  const { results } = useQuery("shortCodes", "targetId", "==", targetId);
  useEffect(() => {
    if (results.length > 0) {
      setId(results[0].shortId);
    }
  }, [results]);
  let {
    data: { qrStyle, ...rest },
  } = useTranslateDocument("/clients/" + user.uid);
  
  useEffect(() => {
    if (qrCodeRef && qrCodeRef.current && qrCodeRef.current.canvas) {
      const ctx = qrCodeRef.current.canvas.current.getContext("2d");
      ctx.font = "bold " + 20 * factor + "px sans-serif";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textRendering = "optimizeLegibility";
      //bold
      ctx.fontWeight = "bold";
      ctx.fillText("#" + id, 115 * factor, 228 * factor);
    }
  }, [id]);

  const saveId = () => {
    let paddedId = id.toString().padStart(3, "0");
    const q = query(
      collection(db, "shortCodes/"),
      where("shortId", "==", paddedId),
      where("targetId", "!=", targetId)
    );
    return getDocs(q).then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        alert("Diese ID existiert bereits");
        return;
      } else {
        if (results.length === 0) {
          const docRef = doc(collection(db, "shortCodes/"));
          return setDoc(docRef, {
            id: docRef.id,
            shortId: paddedId,
            targetId: targetId,
            type: type,
            user: user.uid,
          }).then(() => {
            
            setId(paddedId);
          });
        } else {
          return updateDoc(doc(db, "shortCodes", results[0].id), {
            shortId: paddedId,
          }).then(() => {
            
            setId(paddedId);
          });
        }
      }
    });
  };
  return (
    <div className="ShortLinkMaker">
      <div className="left">
        <StyledInput
          label={"QR Code ID"}
          value={id}
          onChange={(v) => {
            setId(v);
          }}
          maxLength={3}
        ></StyledInput>

        <StyledButton
          onClick={() => {
            // check if ID already exists:
            saveId();
          }}
        >
          <Spacer w={24}></Spacer>
          Neue ID Speichern
          <Spacer w={24}></Spacer>
        </StyledButton>
      </div>
      <div className="right">
        {qrStyle && (
          <>
            <QRCode
              ref={qrCodeRef}
              bgColor={"#ffffff00"}
              fgColor={qrStyle.color}
              value={
                "https://" + rest.subdomain + ".avemus.app/shortLink/" + id
              }
              enableCORS
              size={190 * factor}
              quietZone={20 * factor}
              qrStyle={qrStyle.type}
              ecLevel={"H"}
              removeQrCodeBehindLogo
              eyeRadius={cornerStyles[qrStyle.cornerStyle]}
              logoImage={qrStyle.image}
              logoHeight={qrStyle.size}
              logoWidth={qrStyle.size}
            ></QRCode>
            <Spacer h={12}></Spacer>
            <StyledButton
              onClick={() => {
                let qrData = qrCodeRef.current.canvas.current.toDataURL();
                saveId().then(() => {
                  saveAs(qrData, "qrCode.png");
                });
              }}
            >
              <Spacer w={24}></Spacer>
              Herunterladen
              <Spacer w={24}></Spacer>
            </StyledButton>
          </>
        )}
      </div>
    </div>
  );
}
