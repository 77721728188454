import { doc, updateDoc, deleteDoc } from "firebase/firestore";

import { db } from "../firebase";

export function deleteDocument(path) {
  const docRef = doc(db, path);
  return deleteDoc(docRef);
}

export function updateDocument(path, data) {
  const docRef = doc(db, path);
  return updateDoc(docRef, data);
}
